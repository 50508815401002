import React from "react";
import Alert from '@material-ui/lab/Alert';

export type SeverityType = 'success' | 'info' | 'warning' | 'error';

export interface IPageMsg {
   severity: SeverityType,
   pageMsg: string,
   includeLink: boolean,
}
const PageMsg = (props: IPageMsg) => {
   return (
      <Alert role="validation-alert" variant="filled" severity={props.severity} style={{ 'marginBottom': 30, whiteSpace: 'pre-line' }}>{props.pageMsg}
         {props.includeLink &&
            <div>
               Please enter a different email address or click <a href='https://secure.1placeonline.com' style={{ 'color': 'white' }}>here to login</a>
            </div>
         }
      </Alert>
   );
}

export default PageMsg;