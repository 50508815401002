import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Checkbox, FormControlLabel, Link, Typography, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../style/subscription';
import { IImplementationItem, ISignUpForm } from '../models/subscription';
import { IPageMsg } from './PageMsg';
import ActionButtons from './ActionButtons';
import Packages from './Packages';
import PaymentSummary from './PaymentSummary';
import CustomItems from './CustomItems';

interface IConfirmationProps extends WithStyles<typeof styles> {
   signupFormData: ISignUpForm
   onBack: (value: ISignUpForm) => void
   onValidationFormError: (message: IPageMsg) => void
   onConfirm: (value: ISignUpForm) => void
   isSubmitting: boolean
}

function getTotalCustomItems(items : IImplementationItem[]){
   return items.reduce( (acc, item) =>  acc + (Number(item.priceValue) * item.quantity), 0)
}

function clearCustomItems(items : IImplementationItem[]) : IImplementationItem[]{
   const itemsCopy = [...items]
   itemsCopy.forEach(ii => ii.quantity = 0)
   return itemsCopy;
}

function updateCustomItemQuantity(items : IImplementationItem[], 
   id: number, quantity: number) : IImplementationItem[]{
   const itemsCopy = [...items]
   const itemIndex = itemsCopy.findIndex(item => item.id === id)
   if(itemIndex < 0) return itemsCopy
   itemsCopy[itemIndex].quantity = quantity
   return itemsCopy
}

const Confirmation = (props: IConfirmationProps) => {

   const [signUpFormData, setSignUpFormData] = React.useState<ISignUpForm>(props.signupFormData);
   
   const showImplPackages = signUpFormData.implementationPackages 
      && signUpFormData.implementationPackages.length > 0
      && (signUpFormData.minFranchiseeNumberForImpPack == null 
               || signUpFormData.numberOfSite >= signUpFormData.minFranchiseeNumberForImpPack)

   const showCustomItems = signUpFormData.implementationItems 
      && signUpFormData.implementationItems.length > 0 
      && false //we're no longer showing custom items here, we may change this in the future 

   return (
      <>
         {showImplPackages &&
            <Grid item xs={12}>
               <Packages 
                  signupFormData={signUpFormData}
                  onSelectPrice={(price) => {
                     setSignUpFormData({...signUpFormData, 
                        implementationPackagePrice: price, 
                        implementationItemsPrice: null,
                        implementationItems: clearCustomItems(signUpFormData.implementationItems),
                        proceedWithoutImplementationPackage : false
                     })
                  }} 
               />
            </Grid>
         }
         {showCustomItems && 
            <Grid item xs={12}>
               <CustomItems 
                  signupFormData={signUpFormData} 
                  onEnterQuantity={(id, quantity) => {
                     const customItems = updateCustomItemQuantity(signUpFormData.implementationItems, id, quantity)
                     setSignUpFormData({...signUpFormData,  
                        implementationPackagePrice: null,
                        implementationItemsPrice: getTotalCustomItems(customItems),
                        implementationItems: customItems,                                               
                        proceedWithoutImplementationPackage: false
                     })
                  }}
                  onChangeSelection={(selected) => {
                     if(!selected){
                        setSignUpFormData({
                           ...signUpFormData, 
                           implementationItemsPrice: null,
                           implementationItems: clearCustomItems(signUpFormData.implementationItems)
                        })                        
                     }
                  }}
               />
            </Grid>
         }
        {(showImplPackages || showCustomItems) && 
            !signUpFormData.implementationPackageRequired &&       
            <Grid item xs={12} style={{marginTop: 10, padding: '10px 0 10px 10px'}}>
               <div>
                  <FormControlLabel
                     control={<Checkbox color='primary'
                        checked={signUpFormData.proceedWithoutImplementationPackage}
                        data-testid='no-implementation-package'
                        onChange={(event: any) => {
                           setSignUpFormData({
                              ...signUpFormData, 
                              implementationPackagePrice: null,
                              implementationItemsPrice: null,
                              implementationItems: clearCustomItems(signUpFormData.implementationItems),
                              proceedWithoutImplementationPackage: event.target.checked, 
                           })
                        }}
                     />}
                     label={<Typography variant='body1'>Or proceed without Implementation Package</Typography>}
                  />
                  <hr/>
               </div>
            </Grid>
         }
          {signUpFormData.price && 
            <Grid item xs={12}>
               <PaymentSummary signupFormData={signUpFormData} />
            </Grid>
         }
         <Grid item xs={12} style={{ paddingTop: 20 }}>
            <FormControlLabel
               control={<Checkbox color='primary'
                  checked={signUpFormData.checkedTerm}
                  data-testid='terms-use'
                  onChange={(event: any) => {
                     setSignUpFormData({
                        ...signUpFormData, checkedTerm: event.target.checked
                     })
                  }}
               />}
               label={
                  <div>
                     I have read and I agree to the <Link target='_blank' href='https://www.1placeonline.com/terms-of-use/'>Terms of Use</Link>.
                  </div>
               }
            />
         </Grid>
         <Grid item xs={12}>
            <FormControlLabel
               control={<Checkbox color='primary'
                  data-testid='privacy-policy'
                  checked={signUpFormData.checkedPolicy}
                  onChange={(event: any) => {
                     setSignUpFormData({
                        ...signUpFormData, checkedPolicy: event.target.checked
                     })
                  }}
               />}
               label={<div>I have read and I agree to the <Link target='_blank' href='https://www.1placeonline.com/privacy-policy/'>Privacy Policy</Link></div>}
            />
         </Grid>
         {!props.isSubmitting &&
            <ActionButtons
               onClickBack={() => props.onBack(signUpFormData)}
               showConfirm={true}
               disableConfirm={
                  !signUpFormData.checkedTerm
                  || !signUpFormData.checkedPolicy
                  || (
                     (showImplPackages || showCustomItems)
                     && !signUpFormData.implementationPackagePrice
                     && !signUpFormData.proceedWithoutImplementationPackage
                     && !signUpFormData.implementationItemsPrice //price has to be > 0
                  )
               }
               onClickConfirm={() => props.onConfirm(signUpFormData)}
            />
         }
      </>
   )
}
export default withStyles(styles)(Confirmation);