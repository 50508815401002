import React from 'react';
import { Typography, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../style/subscription';
import { ISignUpForm } from '../models/subscription';
import Alert from '@material-ui/lab/Alert';

interface IConfirmationProps extends WithStyles<typeof styles> {
   signupFormData: ISignUpForm
}

const PaymentSummary = (props: IConfirmationProps) => {

   const priceValue = props.signupFormData.priceValue
   const numberOfCenters = props.signupFormData.numberOfSite
   const subscriptionPrice = Number(priceValue) * Number(numberOfCenters)
   const hasGST = ['australia', 'new zealand'].includes(props.signupFormData.country.toLowerCase())

   //Implementation Package
   const implPackageStripePriceId = props.signupFormData.implementationPackagePrice //selected stripe price (if Implementation Package selected)
   const implPackageSelected = !!implPackageStripePriceId
   
   //Custom Items
   const customItemsTotal = props.signupFormData.implementationItemsPrice // Implementation Items total (if Custom Items selected)
   const customItemsSelected = !!customItemsTotal

   let implPackagePrice;
   let implPackageValue = 0;

   if(implPackageSelected){
      implPackagePrice = props.signupFormData.implementationPackages.find(ip => ip.price === implPackageStripePriceId)!.priceValue
      implPackageValue = Number(implPackagePrice) 
   } else if(customItemsSelected) {
      implPackageValue = customItemsTotal!
   }

 
   return (
      <>
         <Alert severity='info'>
            <Typography variant='body1'>
               <b>Subscription Price </b> 
                  {`$${subscriptionPrice} ($${priceValue} x ${numberOfCenters} centers)`}
            </Typography>               
            {implPackageSelected && 
               <Typography variant='body1'>
                  <b>Implementation Package </b> 
                  {`$${implPackagePrice}`}
               </Typography>
            }
            {customItemsSelected && 
               <Typography variant='body1'>
                  <b>Custom Implementation Package </b> 
                  {`$${customItemsTotal}`}
               </Typography>
            }
            <hr/>
            <Typography variant='body1'>
               <b>Total </b> 
               ${subscriptionPrice + implPackageValue}
               {hasGST && '*'}
               {(implPackageSelected || customItemsSelected)  && ` (For the first month, then $${subscriptionPrice}${hasGST ? '*' : ''} per month)`}
            </Typography>               
         </Alert> 
         {hasGST && 
            <Typography variant='body2' style={{textAlign: 'right'}}>
               *Plus GST
            </Typography>
         }        
      </>
   )  
}
export default withStyles(styles)(PaymentSummary);