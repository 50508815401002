//filter special chars pasted from MS word because they cause error in ZOHO API

const MS_WORD_SPECIAL_CHARS = [
    '\u00AB', // «  1
    '\u00BB', // »  2
    '\u2018', // ‘  3
    '\u2019', // ’  4 
    '\u201A', // ‚  5
    '\u201B', // ‛  6
    '\u201C', // “  7
    '\u201D', // ”  8
    '\u201E', // „  9
    '\u201F', // ‟  10
    '\u2039', // ‹  11
    '\u203A', // ›  12
    '\u2013', // –  13
    '\u2014', // —  14
    '\u2016'  // …  15
  ];
  const MS_WORD_REPLACE_CHARS = [
    '<<', // 1
    '>>', // 2
    "'",  // 3
    "'",  // 4
    "'",  // 5
    "'",  // 6
    '"',  // 7
    '"',  // 8
    '"',  // 9
    '"',  // 10
    '<',  // 11
    '>',  // 12
    '-',  // 13
    '-',  // 14
    '...' // 15
  ];
export const replaceSpecialStrings = function(inputString: string) {
    if(!inputString){
      return inputString;
    }
    var replaceString = ''+inputString;
    for (var i = 0; i < MS_WORD_SPECIAL_CHARS.length; i++) {
      replaceString = replaceString.replace(MS_WORD_SPECIAL_CHARS[i], MS_WORD_REPLACE_CHARS[i]);
    }
    return replaceString;
  };