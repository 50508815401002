import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../style/subscription';
import { ISignUpForm } from '../models/subscription';
import { IPageMsg } from './PageMsg';
import * as EmailValidator from 'email-validator';
import ActionButtons from './ActionButtons';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}$/
interface IUserDetailsProps extends WithStyles<typeof styles> {
   signupFormData: ISignUpForm
   onValidationFormError: (message: IPageMsg) => void
   onNext: (value: ISignUpForm) => void
}
const UserDetails = (props: IUserDetailsProps) => {

   const [signUpFormData, setSignUpFormData] = React.useState<ISignUpForm>(props.signupFormData);
   const [showPassword, setShowPassword] = React.useState(false);

   const next = () => {
      let error;
      if (!signUpFormData.firstName.trim())
         error = 'Please complete the first name field.'
      else if (!signUpFormData.lastName.trim())
         error = 'Please complete the last name field.'
      else if (!signUpFormData.email.trim())
         error = 'Please complete the email field.'
      else if (!EmailValidator.validate(signUpFormData.email))
         error = `Email address ${signUpFormData.email} is invalid.`
      else if (!signUpFormData.telephone.trim())
         error = 'Please complete the mobile number field.'
      else if (!passwordRegex.test(signUpFormData.password))
         error = 'Password is invalid.'


      if (error)
         props.onValidationFormError({
            severity: 'error',
            pageMsg: error,
            includeLink: false
         })
      else
         props.onNext(signUpFormData)
   }

   return (
      <>
         <Grid item xs={12} sm={6}>
            <TextField
               variant='outlined'
               required
               id='firstName'
               name='firstName'
               label='First Name'
               value={signUpFormData.firstName}
               fullWidth
               onChange={(event) => {
                  setSignUpFormData({ ...signUpFormData, firstName: event.target.value })
               }}
            />
         </Grid>
         <Grid item xs={12} sm={6}>
            <TextField
               variant='outlined'
               required
               id='lastName'
               name='lastName'
               label='Last Name'
               value={signUpFormData.lastName}
               fullWidth
               onChange={(event) => {
                  setSignUpFormData({ ...signUpFormData, lastName: event.target.value })
               }}
            />
         </Grid>
         <Grid item xs={12}>
            <TextField
               variant='outlined'
               required
               id='email'
               name='email'
               label='Email'
               value={signUpFormData.email}
               fullWidth
               onChange={(event) => {
                  setSignUpFormData({ ...signUpFormData, email: event.target.value })
               }}
            />
         </Grid>
         <Grid item xs={12}>
            <TextField
               variant='outlined'
               required
               id='telephone'
               name='telephone'
               label='Mobile Number'
               value={signUpFormData.telephone}
               fullWidth
               onChange={(event) => {
                  setSignUpFormData({ ...signUpFormData, telephone: event.target.value })
               }}
            />
         </Grid>
         <Grid item xs={12}>
            <FormControl variant='outlined' fullWidth required>
               <InputLabel htmlFor='outlined-adornment-password'>Password</InputLabel>
               <OutlinedInput
                  name='password'
                  id='password'
                  label='Password'
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                     <InputAdornment position='end'>
                        <IconButton
                           aria-label='toggle password visibility'
                           onClick={() => setShowPassword((show) => !show)}
                           onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault}
                           edge='end'
                        >
                           {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                     </InputAdornment>
                  }
                  role='password'
                  value={signUpFormData.password}
                  onChange={(event) => {
                     setSignUpFormData({
                        ...signUpFormData, password: event.target.value
                     })
                  }}
               />
            </FormControl>
         </Grid>
         <Grid item xs={12}>
            <Alert severity='info'>
               Password must be at least 10 characters long and contain at least 1 number, 1 lower case letter and 1 upper case letter.
            </Alert>
         </Grid>
         <Grid item xs={12} sm={6}>
            <TextField
               variant='outlined'
               id='promoCode'
               name='promoCode'
               label='Promo Code'
               value={signUpFormData.promoCode}
               fullWidth
               onChange={(event) => {
                  setSignUpFormData({ ...signUpFormData, promoCode: event.target.value })
               }}
            />
         </Grid>
         <ActionButtons showNext={true} onClickNext={next} />
      </>
   )
}
export default withStyles(styles)(UserDetails);