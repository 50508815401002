import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link, Box} from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://subscription.1placeonline.com/">
        1Place International Limited
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const styles = (theme: Theme) => createStyles({
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    backgroundColor:'#00307d',
    color:'white',
  },
});

const Footer = () => {
  return (
    <React.Fragment>
        <Box mt={5}>
          <Copyright />
        </Box>
    </React.Fragment>
  );
};
export default withStyles(styles)(Footer);