import Grid from '@material-ui/core/Grid';
import { Button, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../style/subscription';

interface IActionButtonsProps extends WithStyles<typeof styles> {
   disableBack?: boolean
   onClickBack?: () => void
   showNext?: boolean
   onClickNext?: () => void
   showConfirm?: boolean
   disableConfirm?: boolean
   onClickConfirm?: () => void
}

const ActionButtons = (props: IActionButtonsProps) => {
   return (
      <Grid item xs={12} style={{ marginBottom: 0 }}>
         <Grid
            container
            direction='row'
            justify='flex-end'
            alignItems='flex-end'
         >
            <Button
               disabled={props.disableBack}
               onClick={props.onClickBack}
               className={props.classes.back_button}
            >
               Back
            </Button>
            {props.showNext &&
               <Button variant='contained' color='primary'
                  className={props.classes.back_button}
                  onClick={props.onClickNext}>
                  Next
               </Button>
            }
            {props.showConfirm &&
               <Button
                  className={props.classes.back_button}
                  onClick={props.onClickConfirm}
                  disabled={props.disableConfirm}
                  variant='contained'
                  color='primary'
                  id='submit'>
                  <span id='button-text'>Submit</span>
               </Button>
            }
         </Grid>
      </Grid>
   )
}
export default withStyles(styles)(ActionButtons);