import { IClientSecret, IInstanceValidationResponse, IPaymentIntent, ISignUpForm } from "../models/subscription";
import { findCountryFullName } from "../utils/Index";
import { replaceSpecialStrings } from "../utils/StringUtils";

export const updateZoho = async (signUpFormData: ISignUpForm): Promise<number | void> => {
   return fetch('/subscription/zoho/account-contact', {
      method: 'post',
      headers: {
         'Content-type': 'application/json'
      },
      body: JSON.stringify({
         token: 'unsecuredRequest',
         zohoData: {
            firstName: replaceSpecialStrings(signUpFormData.firstName),
            lastName: replaceSpecialStrings(signUpFormData.lastName),
            email: signUpFormData.email.trim(),
            telephone: signUpFormData.telephone,
            role: signUpFormData.role,
            organization: replaceSpecialStrings(signUpFormData.organization),
            numberOfSite: signUpFormData.numberOfSite,
            state: replaceSpecialStrings(signUpFormData.state),
            timezone: signUpFormData.timezone,
            country: signUpFormData.country,
            industry: signUpFormData.industry,
            promoCode: signUpFormData.promoCode,
            partner: signUpFormData.partner,
            plan: signUpFormData.plan
         }
      })
   }).then(response => {
      return response.json();
   }).then(function (contactIdJson) {
      return contactIdJson.id;
   }).catch( err => {
      console.log('Error Updating Zoho')
      console.log(err)      
   });
}

export const validateInstance = async (signUpFormData: ISignUpForm, resultCaptcha: string): 
   Promise<IInstanceValidationResponse> => {

   const emails = [signUpFormData.email]
   if(signUpFormData.users && signUpFormData.users.length > 0){
      if (signUpFormData.users[0].email.length > 0) emails.push(signUpFormData.users[0].email)
      if (signUpFormData.users[1].email.length > 0) emails.push(signUpFormData.users[1].email)
   } 

   const response = await fetch('/subscription/oneplace/validate-franchise', {
      method: 'post',
      headers: {
         'Content-type': 'application/json'
      },
      body: JSON.stringify({
         token: 'unsecuredRequest',
         franchiseData: {
            promoCode: signUpFormData.promoCode,
            country: signUpFormData.country,
            emails,
         },
         grecaptcha: resultCaptcha
      })
   })

   const responseJson = await response.json()

   //partner error
   if (response.status && response.status !== 200) {
      return { error: true, message: responseJson.message }
   } else if (responseJson.emails) { //check email validation
      const emailsResponse: any[] = responseJson.emails
      let errorMsg: string = ''
      emailsResponse.forEach((email: any) => {
         let errors: any[] = []
         if (!email.emailAvailable) errors.push('Email is not available')
         if (!email.domainValid) errors.push('Domain is not valid')
         if (errors.length > 0) {
            errorMsg += `\n${email.email} (${errors.join(' and ')}).`
         }
      })
      if (errorMsg.length > 0) {
         return { error: true, message: `The following emails are invalid: ${errorMsg}` }
      }
   }
   return { 
      error: false, 
      obw: responseJson.obw, 
      price: responseJson.price,
      priceValue: responseJson.priceValue, 
      partner: responseJson.partner, 
      plan: responseJson.plan,
      implementationPackageRequired: responseJson.implementationPackageRequired,
      implementationPackages: responseJson.implementationPackages,
      implementationItems: responseJson.implementationItems,
      minFranchiseeNumberForImpPack: responseJson.minFranchiseeNumberForImpPack,
      promoCode: responseJson.promoCode
   }
}

export const createInstance = async (signUpFormData: ISignUpForm, resultCaptcha: string): Promise<Response | void> => {
   return fetch('/subscription/oneplace/franchise', {
      method: 'post',
      headers: {
         'Content-type': 'application/json'
      },
      body: JSON.stringify({
         token: 'unsecuredRequest',
         franchiseData: {
            firstName: replaceSpecialStrings(signUpFormData.firstName),
            lastName: replaceSpecialStrings(signUpFormData.lastName),
            email: signUpFormData.email.trim(),
            telephone: signUpFormData.telephone,
            password: signUpFormData.password,
            organization: replaceSpecialStrings(signUpFormData.organization),
            numberOfSite: signUpFormData.numberOfSite,
            state: replaceSpecialStrings(signUpFormData.state),
            timezone: signUpFormData.timezone,
            country: findCountryFullName(signUpFormData.country),
            industry: signUpFormData.industry,
            promoCode: signUpFormData.promoCode,
            users: signUpFormData.users,
            checkedPolicy: signUpFormData.checkedPolicy,
            checkedTerm: signUpFormData.checkedTerm,
            userAgent: navigator.userAgent,
            localDateTime: new Date().toLocaleString()
         },
         grecaptcha: resultCaptcha
      })
   }).catch( err => {
      console.log('Error Creating Instance')
      console.log(err)      
   });
}

export const verify = async (result: string): Promise<Response> => {
   return fetch('/verify', {
      method: 'post',
      headers: {
         'Content-type': 'application/json'
      },
      body: JSON.stringify({
         grecaptcha: result,
      })
   });
}

export const createPaymentIntent = async (signUpFormData: ISignUpForm): 
   Promise<IClientSecret> => {
   const response = await fetch('/subscription/create-payment-intent', {
      method: 'post',
      headers: {
         'Content-type': 'application/json'
      },
      body: JSON.stringify({
         token: 'unsecuredRequest',
         stripeUserData: {
            firstName: replaceSpecialStrings(signUpFormData.firstName),
            lastName: replaceSpecialStrings(signUpFormData.lastName),
            email: signUpFormData.email.trim(),
            telephone: signUpFormData.telephone,
            organization: replaceSpecialStrings(signUpFormData.organization),
            state: replaceSpecialStrings(signUpFormData.state),
            timezone: signUpFormData.timezone,
            country: findCountryFullName(signUpFormData.country),
            industry: signUpFormData.industry,
            numberOfSitesToCharge: signUpFormData.numberOfSite,
            promoCode: signUpFormData.promoCode,
            price: signUpFormData.price,
            implementationPackagePrice: signUpFormData.implementationPackagePrice,
            implementationItems: signUpFormData.implementationItems.filter(ii => ii.quantity > 0)
         }
      })
   })
   const json = await response.json()
   return {clientSecret: json.clientSecret, pk: json.pk}
}

export const getPaymentIntent = async (paymentIntentId: string, country: string): 
   Promise<IPaymentIntent | null> => {
   const response = await fetch('/subscription/get-payment-intent', {
      method: 'post',
      headers: {
         'Content-type': 'application/json'
      },
      body: JSON.stringify({ paymentIntentId: paymentIntentId, country: country })
   })
   const json = await response.json()
   return response.ok ? {paymentIntent: json.paymentIntent, pk: json.pk} : null
}