import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { FormControl, InputLabel, MenuItem, Select, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../style/subscription';
import { ISignUpForm } from '../models/subscription';
import { COUNTRY_LIST, POPULAR_COUNTRY_NAME, TIMEZONE_NZ_VALUE } from '../utils/Constant';
import { findTimezonesByCountry } from '../utils/Index';
import { IPageMsg } from './PageMsg';
import ActionButtons from './ActionButtons';

interface IOrganizationDetailsProps extends WithStyles<typeof styles> {
   signupFormData: ISignUpForm
   onValidationFormError: (message: IPageMsg) => void
   onNext: (value: ISignUpForm) => void
   onBack: (value: ISignUpForm) => void
}

const OrganizationDetails = (props: IOrganizationDetailsProps) => {

   const [signUpFormData, setSignUpFormData] = React.useState<ISignUpForm>(props.signupFormData);

   const onChangeCountry = async (event: any) => {
      if (POPULAR_COUNTRY_NAME.NZ === event.target.value) {
         setSignUpFormData({ ...signUpFormData, country: event.target.value, timezone: TIMEZONE_NZ_VALUE })
      } else if (POPULAR_COUNTRY_NAME.UN === event.target.value) {
         setSignUpFormData({ ...signUpFormData, country: event.target.value })
      } else {
         const tz = findTimezonesByCountry(event.target.value)[0]
         setSignUpFormData({ ...signUpFormData, country: event.target.value, timezone: tz?.value || '' })
      }
   }

   const next = () => {
      let error  

      if (!signUpFormData.organization.trim())
         error = 'Please complete the organization field.'
      else if (!signUpFormData.numberOfSite || signUpFormData.numberOfSite < 1)
         error = 'Please complete the Number of Centers field.'
      else if (!signUpFormData.timezone.trim())
         error = 'Please complete the timezone field.'
      else if (!signUpFormData.country.trim())
         error = 'Please complete the country field.'      

      if (error)
         props.onValidationFormError({
            severity: 'error',
            pageMsg: error,
            includeLink: false
         })
      else
         props.onNext(signUpFormData)
   }

   return (
      <>
         <Grid item xs={12}>
            <TextField
               variant='outlined'
               required
               id='organization'
               name='organization'
               label='Organization Name'
               value={signUpFormData.organization}
               fullWidth
               onChange={(event) => {
                  setSignUpFormData({
                     ...signUpFormData, organization: event.target.value
                  })
               }}
            />
         </Grid>
         <Grid item xs={12}>
            <TextField
               variant='outlined'
               id='position'
               name='position'
               label='Role/Position'
               value={signUpFormData.role}
               fullWidth
               onChange={(event) => {
                  setSignUpFormData({ ...signUpFormData, role: event.target.value })
               }}
            />
         </Grid>
         <Grid item xs={12} sm={6}>
            <FormControl className={props.classes.phone_class}>
               <InputLabel required={true} id='country-label'>Country</InputLabel>
               <Select
                  variant='outlined'
                  required
                  labelId='country-label'
                  id='country'
                  value={signUpFormData.country}
                  onChange={onChangeCountry}
               >
                  {COUNTRY_LIST.map((country) => (
                     <MenuItem key={country.value} value={country.value}>
                        {country.value}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
         </Grid>
         {signUpFormData.country && signUpFormData.country !== 'New Zealand' && <>
            <Grid item xs={12} sm={6}>
               <TextField
                  id='state'
                  name='state'
                  label='State'
                  fullWidth
                  variant='outlined'
                  value={signUpFormData.state}
                  onChange={(event) => {
                     setSignUpFormData({
                        ...signUpFormData, state: event.target.value
                     })
                  }}
               />
            </Grid>
         </>}
         <Grid item xs={12} sm={6}>
            <FormControl className={props.classes.phone_class}>
               <InputLabel required={true} id='timezone-label'>Timezone</InputLabel>
               <Select variant='outlined'
                  required
                  labelId='timezone-label'
                  id='timezone'
                  value={signUpFormData.timezone}
                  onChange={(event: any) => {
                     setSignUpFormData({
                        ...signUpFormData, timezone: event.target.value
                     })
                  }}
               >
                  {findTimezonesByCountry(signUpFormData.country).map((timezone) => (
                     <MenuItem key={timezone.value} value={timezone.value}>
                        {timezone.label}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
         </Grid>
         <Grid item xs={12} sm={6}>
            <TextField
               required
               variant='outlined'
               id='siteNumber'
               name='siteNumber'
               label='Number of Centers'
               value={signUpFormData.numberOfSite}
               InputProps={{ inputProps: { min: 1 } }}
               fullWidth
               type='number'
               onChange={(event) => {
                  setSignUpFormData({
                     ...signUpFormData, numberOfSite: parseInt(event.target.value)
                  })
               }}
            />
         </Grid>         
         <ActionButtons
            disableBack={false}
            onClickBack={() => props.onBack(signUpFormData)}
            showNext={true}
            onClickNext={next}
         />
      </>
   )
}
export default withStyles(styles)(OrganizationDetails);