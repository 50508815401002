import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Radio, Typography, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../style/subscription';
import { ISignUpForm } from '../models/subscription';

interface IPackagesProps extends WithStyles<typeof styles> {
   signupFormData: ISignUpForm
   onSelectPrice: (price: string) => void
}

const Packages = (props: IPackagesProps) => {

   const selectedPrice = props.signupFormData.implementationPackagePrice //stripe price
   
   return (
      <>
         <Grid item xs={12} >
            <Typography variant='body1'>Select the Implementation Package</Typography>
            <hr/>
         </Grid>
         {props.signupFormData.implementationPackages.map((item, idx) => {

               let color = '#0066b2'
            
               return (
                  <Grid 
                     container             
                     key={idx} 
                     style={{
                        marginTop: 10, 
                        alignItems: 'stretch', 
                        cursor: 'pointer'
                     }} 
                     onClick={() => props.onSelectPrice(item.price)}
                  >
                     <Grid 
                        container
                        style={{border: `2px solid ${color}`, borderRadius: 5}}
                     > 
                        <Grid item xs={6} style={{ backgroundColor: color, color: 'white', display: 'flex'}}>
                           <Grid 
                              item 
                              xs={4}                  
                           >                     
                              <Radio color='primary' checked={selectedPrice === item.price} style={{color: 'white'}} />              
                           </Grid>                       
                           <Grid 
                              item 
                              xs={8}                            
                              style={{alignSelf: 'center'}}
                           >
                              <Typography variant='body1'><b>{item.label}</b></Typography>
                           </Grid>
                        </Grid>
                        <Grid 
                           item 
                           xs={6} 
                           style={{textAlign: 'center', paddingRight: 5, alignSelf: 'center' }}
                        >
                           <Typography variant='body1'>${item.priceValue}</Typography>
                        </Grid>
                        {item.description && 
                           <Grid 
                              item 
                              xs={12} 
                              style={{padding: 10, borderTop: `2px solid ${color}`}}
                           >
                              <Typography variant='body1' style={{whiteSpace: 'pre-wrap'}}>{item.description} </Typography>
                           </Grid>
                        }
                     </Grid>                  
                  </Grid>
               )            
            })
         }
      </>
   )
}
export default withStyles(styles)(Packages);