import { COUNTRY_LIST, DEFAULT_COUNTRY_FULL_NAME, DEFAULT_TIMEZONE_ID, POPULAR_COUNTRY_NAME, TIMEZONE_LIST } from "./Constant";

export const findCountryFullName = (countryCode: string | null) => {
   if (!countryCode) {
      //country code is null, this should not happen
      return DEFAULT_COUNTRY_FULL_NAME;
   }
   var matchCountryByCode = COUNTRY_LIST.find((c) => countryCode.toLowerCase() === c.value.toLowerCase());
   if (!matchCountryByCode) {
      //user passing in country code rather than country full name, we will suppport both
      matchCountryByCode = COUNTRY_LIST.find((c) => countryCode.toLowerCase() === c.code.toLowerCase());
   }
   if (matchCountryByCode) {
      return matchCountryByCode.value;
   } else {
      return DEFAULT_COUNTRY_FULL_NAME;
   }
};

export const findCountryIsoName = (countryFullName: string) => {
   const isoName = COUNTRY_LIST.find((c) => countryFullName.toLowerCase() === c.value.toLowerCase());
   return isoName!.code
}

export const findTimezonesByCountry = (countryCode: string) => {
   let countryName = Object.values(POPULAR_COUNTRY_NAME).find((c) => countryCode.toLowerCase() === c.toLowerCase());

   if (!countryName) {
      // show complete timezones list for other countries
      countryName = POPULAR_COUNTRY_NAME.UN;
   }

   return TIMEZONE_LIST.filter((t) => t.country === countryName);
};

export const findTimezoneByCountry = (countryName: string): string => {
   return countryName ? TIMEZONE_LIST.filter((t) => t.country === countryName)
      .map((t1) => t1.value)[0]
      : DEFAULT_TIMEZONE_ID;
}