import { createStyles, Theme } from '@material-ui/core/styles';

export const styles = (theme: Theme) => createStyles({
   '@global': {
      body: {
         backgroundColor: '#FFFFFF'
      },
      ul: {
         margin: 0,
         padding: 0,
         listStyle: 'none',
      },
   },
   root: {
      minHeight: '100vh',
   },
   image: {
      backgroundImage: 'url(landing_screen_partner.png)',
      backgroundRepeat: 'no-repeat',
      backgroundColor:
         theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'top',
      MozBackgroundSize: '700px 573px',
      WebkitBackgroundSize: '700px 573px',
   },
   toolbar: {
      flexWrap: 'wrap',
      alignContent: 'flex-start',
   },
   title: {
      marginBottom: 30,
   },
   loginDetails: {
      justifyContent: "flex-end",
   },
   nav: {
      justifyContent: "flex-end",
   },
   header_link: {
      color: 'white',
      textDecoration: 'none',

   },
   link: {
      margin: theme.spacing(1, 1.5),
      color: '#749ACC',
   },
   large_screen_content: {
      padding: theme.spacing(8, 0, 6),
   },
   mobile_screen_content: {
      padding: theme.spacing(3, 2, 0),
   },
   pad_class: {
      minWidth: '100%',
   },
   phone_class: {
      minWidth: '100%',
   },
   backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#FFFFFF',
   },
   back_button: {
      marginRight: '10px',
   }
});
