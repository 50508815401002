import React from 'react'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import Subscription from './pages/Subscription'

export const AppRouter = () => {
   return (
      <BrowserRouter>
         <Switch>
            <Route exact path='/' component={Subscription}  />           
            <Route path="*" >
               <Redirect to={{
                  pathname: '/', 
                  search: window.location.search.replace('partner','promo_code') 
               }}/>
            </Route>
         </Switch>
      </BrowserRouter>
   )
}
