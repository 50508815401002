import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

const useStyles = makeStyles((theme) => ({
   root: {
      width: '100%',
   },
   backButton: {
      marginRight: theme.spacing(1),
   },
   instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
   },
}))

const steps = [
   {key: 'user_details', label: 'Your Details',},
   {key: 'org_details', label: 'Organization Details'},
   {key: 'optional_details', label: 'Add Users'},
   {key: 'confirm', label: 'Confirmation'},
   {key: 'payment', label: 'Payment'}
] as const

export type StepKey = typeof steps[number]['key']

export interface IStepperProps{
   step: StepKey
   show: {
      optional: boolean,
      payment: boolean
   }
}

export const SubscriptionStepper = (props: IStepperProps) => {
   const classes = useStyles()
   return (
      <div className={classes.root}>
         <Stepper 
            activeStep={
               steps.findIndex(s => s.key === props.step)
            } 
            alternativeLabel
         >
            {steps
               .filter(s => 
                  (s.key !== 'optional_details' || props.show.optional) 
                  && 
                  (s.key !== 'payment' || props.show.payment)
               )
               .map((s) => (
                  <Step key={s.key}>
                     <StepLabel>{s.label}</StepLabel>
                  </Step>
               ))
            }
         </Stepper>
      </div>
   )
}