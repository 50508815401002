export const PARTNER_LIST = ['ACA',
                      'Astute',
                      'Child Care Success',
                      'Early Childcare Exchange',
                      'Early Childcare Investigation',
                      'Early Childcare Ventures',
                      'Florida Head Start',
                      'Headstart',
                      'Infocare',
                      'Kidkare',
                      'Kidsoft',
                      'Kindyhub',
                      'Sprouts',
                      'Other',];
export const LEAD_SOURCE_LIST = ['Advertisment',
                      'Cold Call',
                      'Google Search',
                      'Partner Referral',
                      'Trade Show',
                      'Website Enquiry',
                      'Word Of Mouth',
                    ];


export const TIMEZONE_NZ_VALUE = 'Pacific/Auckland';

export const DEFAULT_TIMEZONE_ID = 'America/New_York';

export const TIMEZONE_LIST = [
    {country:'New Zealand', value:'Pacific/Auckland', label:'Pacific/Auckland'},
    {country:'Australia', value:'Australia/Melbourne', label:'Australia/Melbourne'},
    {country:'Australia', value:'Australia/Sydney', label:'Australia/Sydney'},
    {country:'Australia', value:'Australia/Brisbane', label:'Australia/Brisbane'},
    {country:'Australia', value:'Australia/Adelaide', label:'Australia/Adelaide'},
    {country:'Australia', value:'Australia/Perth', label:'Australia/Perth'},
    {country:'Australia', value:'Australia/Darwin', label:'Australia/Darwin'},
    {country:'Australia', value:'Australia/Hobart', label:'Australia/Hobart'},
    {country:'United States of America', value:'America/New_York', label:'Eastern Time'},
    {country:'United States of America', value:'America/Chicago',label:'Central Time'},
    {country:'United States of America', value:'America/Denver',label:'Mountain Time'},
    {country:'United States of America', value:'America/Los_Angeles',label:'Pacific Time'},
    {country:'Canada', value:'Canada/Pacific',label:'Pacific Time'},
    {country:'Canada', value:'Canada/Mountain',label:'Mountain Time'},
    {country:'Canada', value:'Canada/Central',label:'Central Time'},
    {country:'Canada', value:'Canada/Eastern', label:'Eastern Time'},    
    {country:'Canada', value:'Canada/Atlantic', label:'Atlantic Time'},
    {country:'Unknown', value:'Africa/Abidjan',label:'Africa/Abidjan'},
    {country:'Unknown', value:'Africa/Accra',label:'Africa/Accra'},
    {country:'Unknown', value:'Africa/Addis_Ababa',label:'Africa/Addis_Ababa'},
    {country:'Unknown', value:'Africa/Algiers',label:'Africa/Algiers'},
    {country:'Unknown', value:'Africa/Asmara',label:'Africa/Asmara'},
    {country:'Unknown', value:'Africa/Asmera',label:'Africa/Asmera'},
    {country:'Unknown', value:'Africa/Bamako',label:'Africa/Bamako'},
    {country:'Unknown', value:'Africa/Bangui',label:'Africa/Bangui'},
    {country:'Unknown', value:'Africa/Banjul',label:'Africa/Banjul'},
    {country:'Unknown', value:'Africa/Bissau',label:'Africa/Bissau'},
    {country:'Unknown', value:'Africa/Blantyre',label:'Africa/Blantyre'},
    {country:'Unknown', value:'Africa/Brazzaville',label:'Africa/Brazzaville'},
    {country:'Unknown', value:'Africa/Bujumbura',label:'Africa/Bujumbura'},
    {country:'Unknown', value:'Africa/Cairo',label:'Africa/Cairo'},
    {country:'Unknown', value:'Africa/Casablanca',label:'Africa/Casablanca'},
    {country:'Unknown', value:'Africa/Ceuta',label:'Africa/Ceuta'},
    {country:'Unknown', value:'Africa/Conakry',label:'Africa/Conakry'},
    {country:'Unknown', value:'Africa/Dakar',label:'Africa/Dakar'},
    {country:'Unknown', value:'Africa/Dar_es_Salaam',label:'Africa/Dar_es_Salaam'},
    {country:'Unknown', value:'Africa/Djibouti',label:'Africa/Djibouti'},
    {country:'Unknown', value:'Africa/Douala',label:'Africa/Douala'},
    {country:'Unknown', value:'Africa/El_Aaiun',label:'Africa/El_Aaiun'},
    {country:'Unknown', value:'Africa/Freetown',label:'Africa/Freetown'},
    {country:'Unknown', value:'Africa/Gaborone',label:'Africa/Gaborone'},
    {country:'Unknown', value:'Africa/Harare',label:'Africa/Harare'},
    {country:'Unknown', value:'Africa/Johannesburg',label:'Africa/Johannesburg'},
    {country:'Unknown', value:'Africa/Kampala',label:'Africa/Kampala'},
    {country:'Unknown', value:'Africa/Khartoum',label:'Africa/Khartoum'},
    {country:'Unknown', value:'Africa/Kigali',label:'Africa/Kigali'},
    {country:'Unknown', value:'Africa/Kinshasa',label:'Africa/Kinshasa'},
    {country:'Unknown', value:'Africa/Lagos',label:'Africa/Lagos'},
    {country:'Unknown', value:'Africa/Libreville',label:'Africa/Libreville'},
    {country:'Unknown', value:'Africa/Lome',label:'Africa/Lome'},
    {country:'Unknown', value:'Africa/Luanda',label:'Africa/Luanda'},
    {country:'Unknown', value:'Africa/Lubumbashi',label:'Africa/Lubumbashi'},
    {country:'Unknown', value:'Africa/Lusaka',label:'Africa/Lusaka'},
    {country:'Unknown', value:'Africa/Malabo',label:'Africa/Malabo'},
    {country:'Unknown', value:'Africa/Maputo',label:'Africa/Maputo'},
    {country:'Unknown', value:'Africa/Maseru',label:'Africa/Maseru'},
    {country:'Unknown', value:'Africa/Mbabane',label:'Africa/Mbabane'},
    {country:'Unknown', value:'Africa/Mogadishu',label:'Africa/Mogadishu'},
    {country:'Unknown', value:'Africa/Monrovia',label:'Africa/Monrovia'},
    {country:'Unknown', value:'Africa/Nairobi',label:'Africa/Nairobi'},
    {country:'Unknown', value:'Africa/Ndjamena',label:'Africa/Ndjamena'},
    {country:'Unknown', value:'Africa/Niamey',label:'Africa/Niamey'},
    {country:'Unknown', value:'Africa/Nouakchott',label:'Africa/Nouakchott'},
    {country:'Unknown', value:'Africa/Ouagadougou',label:'Africa/Ouagadougou'},
    {country:'Unknown', value:'Africa/Porto-Novo',label:'Africa/Porto-Novo'},
    {country:'Unknown', value:'Africa/Sao_Tome',label:'Africa/Sao_Tome'},
    {country:'Unknown', value:'Africa/Timbuktu',label:'Africa/Timbuktu'},
    {country:'Unknown', value:'Africa/Tripoli',label:'Africa/Tripoli'},
    {country:'Unknown', value:'Africa/Tunis',label:'Africa/Tunis'},
    {country:'Unknown', value:'Africa/Windhoek',label:'Africa/Windhoek'},
    {country:'Unknown', value:'America/Adak',label:'America/Adak'},
    {country:'Unknown', value:'America/Anchorage',label:'America/Anchorage'},
    {country:'Unknown', value:'America/Anguilla',label:'America/Anguilla'},
    {country:'Unknown', value:'America/Antigua',label:'America/Antigua'},
    {country:'Unknown', value:'America/Araguaina',label:'America/Araguaina'},
    {country:'Unknown', value:'America/Argentina/Buenos_Aires',label:'America/Argentina/Buenos_Aires'},
    {country:'Unknown', value:'America/Argentina/Catamarca',label:'America/Argentina/Catamarca'},
    {country:'Unknown', value:'America/Argentina/ComodRivadavia',label:'America/Argentina/ComodRivadavia'},
    {country:'Unknown', value:'America/Argentina/Cordoba',label:'America/Argentina/Cordoba'},
    {country:'Unknown', value:'America/Argentina/Jujuy',label:'America/Argentina/Jujuy'},
    {country:'Unknown', value:'America/Argentina/La_Rioja',label:'America/Argentina/La_Rioja'},
    {country:'Unknown', value:'America/Argentina/Mendoza',label:'America/Argentina/Mendoza'},
    {country:'Unknown', value:'America/Argentina/Rio_Gallegos',label:'America/Argentina/Rio_Gallegos'},
    {country:'Unknown', value:'America/Argentina/Salta',label:'America/Argentina/Salta'},
    {country:'Unknown', value:'America/Argentina/San_Juan',label:'America/Argentina/San_Juan'},
    {country:'Unknown', value:'America/Argentina/San_Luis',label:'America/Argentina/San_Luis'},
    {country:'Unknown', value:'America/Argentina/Tucuman',label:'America/Argentina/Tucuman'},
    {country:'Unknown', value:'America/Argentina/Ushuaia',label:'America/Argentina/Ushuaia'},
    {country:'Unknown', value:'America/Aruba',label:'America/Aruba'},
    {country:'Unknown', value:'America/Asuncion',label:'America/Asuncion'},
    {country:'Unknown', value:'America/Atikokan',label:'America/Atikokan'},
    {country:'Unknown', value:'America/Atka',label:'America/Atka'},
    {country:'Unknown', value:'America/Bahia',label:'America/Bahia'},
    {country:'Unknown', value:'America/Barbados',label:'America/Barbados'},
    {country:'Unknown', value:'America/Belem',label:'America/Belem'},
    {country:'Unknown', value:'America/Belize',label:'America/Belize'},
    {country:'Unknown', value:'America/Blanc-Sablon',label:'America/Blanc-Sablon'},
    {country:'Unknown', value:'America/Boa_Vista',label:'America/Boa_Vista'},
    {country:'Unknown', value:'America/Bogota',label:'America/Bogota'},
    {country:'Unknown', value:'America/Boise',label:'America/Boise'},
    {country:'Unknown', value:'America/Buenos_Aires',label:'America/Buenos_Aires'},
    {country:'Unknown', value:'America/Cambridge_Bay',label:'America/Cambridge_Bay'},
    {country:'Unknown', value:'America/Campo_Grande',label:'America/Campo_Grande'},
    {country:'Unknown', value:'America/Cancun',label:'America/Cancun'},
    {country:'Unknown', value:'America/Caracas',label:'America/Caracas'},
    {country:'Unknown', value:'America/Catamarca',label:'America/Catamarca'},
    {country:'Unknown', value:'America/Cayenne',label:'America/Cayenne'},
    {country:'Unknown', value:'America/Cayman',label:'America/Cayman'},
    {country:'Unknown', value:'America/Chihuahua',label:'America/Chihuahua'},
    {country:'Unknown', value:'America/Coral_Harbour',label:'America/Coral_Harbour'},
    {country:'Unknown', value:'America/Cordoba',label:'America/Cordoba'},
    {country:'Unknown', value:'America/Costa_Rica',label:'America/Costa_Rica'},
    {country:'Unknown', value:'America/Creston',label:'America/Creston'},
    {country:'Unknown', value:'America/Cuiaba',label:'America/Cuiaba'},
    {country:'Unknown', value:'America/Curacao',label:'America/Curacao'},
    {country:'Unknown', value:'America/Danmarkshavn',label:'America/Danmarkshavn'},
    {country:'Unknown', value:'America/Dawson',label:'America/Dawson'},
    {country:'Unknown', value:'America/Dawson_Creek',label:'America/Dawson_Creek'},
    {country:'Unknown', value:'America/Detroit',label:'America/Detroit'},
    {country:'Unknown', value:'America/Dominica',label:'America/Dominica'},
    {country:'Unknown', value:'America/Edmonton',label:'America/Edmonton'},
    {country:'Unknown', value:'America/Eirunepe',label:'America/Eirunepe'},
    {country:'Unknown', value:'America/El_Salvador',label:'America/El_Salvador'},
    {country:'Unknown', value:'America/Ensenada',label:'America/Ensenada'},
    {country:'Unknown', value:'America/Fort_Wayne',label:'America/Fort_Wayne'},
    {country:'Unknown', value:'America/Fortaleza',label:'America/Fortaleza'},
    {country:'Unknown', value:'America/Glace_Bay',label:'America/Glace_Bay'},
    {country:'Unknown', value:'America/Godthab',label:'America/Godthab'},
    {country:'Unknown', value:'America/Goose_Bay',label:'America/Goose_Bay'},
    {country:'Unknown', value:'America/Grand_Turk',label:'America/Grand_Turk'},
    {country:'Unknown', value:'America/Grenada',label:'America/Grenada'},
    {country:'Unknown', value:'America/Guadeloupe',label:'America/Guadeloupe'},
    {country:'Unknown', value:'America/Guatemala',label:'America/Guatemala'},
    {country:'Unknown', value:'America/Guayaquil',label:'America/Guayaquil'},
    {country:'Unknown', value:'America/Guyana',label:'America/Guyana'},
    {country:'Unknown', value:'America/Halifax',label:'America/Halifax'},
    {country:'Unknown', value:'America/Havana',label:'America/Havana'},
    {country:'Unknown', value:'America/Hermosillo',label:'America/Hermosillo'},
    {country:'Unknown', value:'America/Indiana/Indianapolis',label:'America/Indiana/Indianapolis'},
    {country:'Unknown', value:'America/Indiana/Knox',label:'America/Indiana/Knox'},
    {country:'Unknown', value:'America/Indiana/Marengo',label:'America/Indiana/Marengo'},
    {country:'Unknown', value:'America/Indiana/Petersburg',label:'America/Indiana/Petersburg'},
    {country:'Unknown', value:'America/Indiana/Tell_City',label:'America/Indiana/Tell_City'},
    {country:'Unknown', value:'America/Indiana/Vevay',label:'America/Indiana/Vevay'},
    {country:'Unknown', value:'America/Indiana/Vincennes',label:'America/Indiana/Vincennes'},
    {country:'Unknown', value:'America/Indiana/Winamac',label:'America/Indiana/Winamac'},
    {country:'Unknown', value:'America/Indianapolis',label:'America/Indianapolis'},
    {country:'Unknown', value:'America/Inuvik',label:'America/Inuvik'},
    {country:'Unknown', value:'America/Iqaluit',label:'America/Iqaluit'},
    {country:'Unknown', value:'America/Jamaica',label:'America/Jamaica'},
    {country:'Unknown', value:'America/Jujuy',label:'America/Jujuy'},
    {country:'Unknown', value:'America/Juneau',label:'America/Juneau'},
    {country:'Unknown', value:'America/Kentucky/Louisville',label:'America/Kentucky/Louisville'},
    {country:'Unknown', value:'America/Kentucky/Monticello',label:'America/Kentucky/Monticello'},
    {country:'Unknown', value:'America/Knox_IN',label:'America/Knox_IN'},
    {country:'Unknown', value:'America/La_Paz',label:'America/La_Paz'},
    {country:'Unknown', value:'America/Lima',label:'America/Lima'},
    {country:'Unknown', value:'America/Louisville',label:'America/Louisville'},
    {country:'Unknown', value:'America/Maceio',label:'America/Maceio'},
    {country:'Unknown', value:'America/Managua',label:'America/Managua'},
    {country:'Unknown', value:'America/Manaus',label:'America/Manaus'},
    {country:'Unknown', value:'America/Marigot',label:'America/Marigot'},
    {country:'Unknown', value:'America/Martinique',label:'America/Martinique'},
    {country:'Unknown', value:'America/Mazatlan',label:'America/Mazatlan'},
    {country:'Unknown', value:'America/Mendoza',label:'America/Mendoza'},
    {country:'Unknown', value:'America/Menominee',label:'America/Menominee'},
    {country:'Unknown', value:'America/Merida',label:'America/Merida'},
    {country:'Unknown', value:'America/Mexico_City',label:'America/Mexico_City'},
    {country:'Unknown', value:'America/Miquelon',label:'America/Miquelon'},
    {country:'Unknown', value:'America/Moncton',label:'America/Moncton'},
    {country:'Unknown', value:'America/Monterrey',label:'America/Monterrey'},
    {country:'Unknown', value:'America/Montevideo',label:'America/Montevideo'},
    {country:'Unknown', value:'America/Montreal',label:'America/Montreal'},
    {country:'Unknown', value:'America/Montserrat',label:'America/Montserrat'},
    {country:'Unknown', value:'America/Nassau',label:'America/Nassau'},
    {country:'Unknown', value:'America/Nipigon',label:'America/Nipigon'},
    {country:'Unknown', value:'America/Nome',label:'America/Nome'},
    {country:'Unknown', value:'America/Noronha',label:'America/Noronha'},
    {country:'Unknown', value:'America/North_Dakota/Center',label:'America/North_Dakota/Center'},
    {country:'Unknown', value:'America/North_Dakota/New_Salem',label:'America/North_Dakota/New_Salem'},
    {country:'Unknown', value:'America/Panama',label:'America/Panama'},
    {country:'Unknown', value:'America/Pangnirtung',label:'America/Pangnirtung'},
    {country:'Unknown', value:'America/Paramaribo',label:'America/Paramaribo'},
    {country:'Unknown', value:'America/Phoenix',label:'America/Phoenix'},
    {country:'Unknown', value:'America/Port_of_Spain',label:'America/Port_of_Spain'},
    {country:'Unknown', value:'America/Port-au-Prince',label:'America/Port-au-Prince'},
    {country:'Unknown', value:'America/Porto_Acre',label:'America/Porto_Acre'},
    {country:'Unknown', value:'America/Porto_Velho',label:'America/Porto_Velho'},
    {country:'Unknown', value:'America/Puerto_Rico',label:'America/Puerto_Rico'},
    {country:'Unknown', value:'America/Rainy_River',label:'America/Rainy_River'},
    {country:'Unknown', value:'America/Rankin_Inlet',label:'America/Rankin_Inlet'},
    {country:'Unknown', value:'America/Recife',label:'America/Recife'},
    {country:'Unknown', value:'America/Regina',label:'America/Regina'},
    {country:'Unknown', value:'America/Resolute',label:'America/Resolute'},
    {country:'Unknown', value:'America/Rio_Branco',label:'America/Rio_Branco'},
    {country:'Unknown', value:'America/Rosario',label:'America/Rosario'},
    {country:'Unknown', value:'America/Santarem',label:'America/Santarem'},
    {country:'Unknown', value:'America/Santiago',label:'America/Santiago'},
    {country:'Unknown', value:'America/Santo_Domingo',label:'America/Santo_Domingo'},
    {country:'Unknown', value:'America/Sao_Paulo',label:'America/Sao_Paulo'},
    {country:'Unknown', value:'America/Scoresbysund',label:'America/Scoresbysund'},
    {country:'Unknown', value:'America/Shiprock',label:'America/Shiprock'},
    {country:'Unknown', value:'America/St_Barthelemy',label:'America/St_Barthelemy'},
    {country:'Unknown', value:'America/St_Johns',label:'America/St_Johns'},
    {country:'Unknown', value:'America/St_Kitts',label:'America/St_Kitts'},
    {country:'Unknown', value:'America/St_Lucia',label:'America/St_Lucia'},
    {country:'Unknown', value:'America/St_Thomas',label:'America/St_Thomas'},
    {country:'Unknown', value:'America/St_Vincent',label:'America/St_Vincent'},
    {country:'Unknown', value:'America/Swift_Current',label:'America/Swift_Current'},
    {country:'Unknown', value:'America/Tegucigalpa',label:'America/Tegucigalpa'},
    {country:'Unknown', value:'America/Thule',label:'America/Thule'},
    {country:'Unknown', value:'America/Thunder_Bay',label:'America/Thunder_Bay'},
    {country:'Unknown', value:'America/Tijuana',label:'America/Tijuana'},
    {country:'Unknown', value:'America/Toronto',label:'America/Toronto'},
    {country:'Unknown', value:'America/Tortola',label:'America/Tortola'},
    {country:'Unknown', value:'America/Vancouver',label:'America/Vancouver'},
    {country:'Unknown', value:'America/Virgin',label:'America/Virgin'},
    {country:'Unknown', value:'America/Whitehorse',label:'America/Whitehorse'},
    {country:'Unknown', value:'America/Winnipeg',label:'America/Winnipeg'},
    {country:'Unknown', value:'America/Yakutat',label:'America/Yakutat'},
    {country:'Unknown', value:'America/Yellowknife',label:'America/Yellowknife'},
    {country:'Unknown', value:'Antarctica/Casey',label:'Antarctica/Casey'},
    {country:'Unknown', value:'Antarctica/Davis',label:'Antarctica/Davis'},
    {country:'Unknown', value:'Antarctica/DumontDUrville',label:'Antarctica/DumontDUrville'},
    {country:'Unknown', value:'Antarctica/Mawson',label:'Antarctica/Mawson'},
    {country:'Unknown', value:'Antarctica/McMurdo',label:'Antarctica/McMurdo'},
    {country:'Unknown', value:'Antarctica/Palmer',label:'Antarctica/Palmer'},
    {country:'Unknown', value:'Antarctica/Rothera',label:'Antarctica/Rothera'},
    {country:'Unknown', value:'Antarctica/South_Pole',label:'Antarctica/South_Pole'},
    {country:'Unknown', value:'Antarctica/Syowa',label:'Antarctica/Syowa'},
    {country:'Unknown', value:'Antarctica/Vostok',label:'Antarctica/Vostok'},
    {country:'Unknown', value:'Arctic/Longyearbyen',label:'Arctic/Longyearbyen'},
    {country:'Unknown', value:'Asia/Aden',label:'Asia/Aden'},
    {country:'Unknown', value:'Asia/Almaty',label:'Asia/Almaty'},
    {country:'Unknown', value:'Asia/Amman',label:'Asia/Amman'},
    {country:'Unknown', value:'Asia/Anadyr',label:'Asia/Anadyr'},
    {country:'Unknown', value:'Asia/Aqtau',label:'Asia/Aqtau'},
    {country:'Unknown', value:'Asia/Aqtobe',label:'Asia/Aqtobe'},
    {country:'Unknown', value:'Asia/Ashgabat',label:'Asia/Ashgabat'},
    {country:'Unknown', value:'Asia/Ashkhabad',label:'Asia/Ashkhabad'},
    {country:'Unknown', value:'Asia/Baghdad',label:'Asia/Baghdad'},
    {country:'Unknown', value:'Asia/Bahrain',label:'Asia/Bahrain'},
    {country:'Unknown', value:'Asia/Baku',label:'Asia/Baku'},
    {country:'Unknown', value:'Asia/Bangkok',label:'Asia/Bangkok'},
    {country:'Unknown', value:'Asia/Beirut',label:'Asia/Beirut'},
    {country:'Unknown', value:'Asia/Bishkek',label:'Asia/Bishkek'},
    {country:'Unknown', value:'Asia/Brunei',label:'Asia/Brunei'},
    {country:'Unknown', value:'Asia/Calcutta',label:'Asia/Calcutta'},
    {country:'Unknown', value:'Asia/Choibalsan',label:'Asia/Choibalsan'},
    {country:'Unknown', value:'Asia/Chongqing',label:'Asia/Chongqing'},
    {country:'Unknown', value:'Asia/Chungking',label:'Asia/Chungking'},
    {country:'Unknown', value:'Asia/Colombo',label:'Asia/Colombo'},
    {country:'Unknown', value:'Asia/Dacca',label:'Asia/Dacca'},
    {country:'Unknown', value:'Asia/Damascus',label:'Asia/Damascus'},
    {country:'Unknown', value:'Asia/Dhaka',label:'Asia/Dhaka'},
    {country:'Unknown', value:'Asia/Dili',label:'Asia/Dili'},
    {country:'Unknown', value:'Asia/Dubai',label:'Asia/Dubai'},
    {country:'Unknown', value:'Asia/Dushanbe',label:'Asia/Dushanbe'},
    {country:'Unknown', value:'Asia/Gaza',label:'Asia/Gaza'},
    {country:'Unknown', value:'Asia/Harbin',label:'Asia/Harbin'},
    {country:'Unknown', value:'Asia/Ho_Chi_Minh',label:'Asia/Ho_Chi_Minh'},
    {country:'Unknown', value:'Asia/Hong_Kong',label:'Asia/Hong_Kong'},
    {country:'Unknown', value:'Asia/Hovd',label:'Asia/Hovd'},
    {country:'Unknown', value:'Asia/Irkutsk',label:'Asia/Irkutsk'},
    {country:'Unknown', value:'Asia/Istanbul',label:'Asia/Istanbul'},
    {country:'Unknown', value:'Asia/Jakarta',label:'Asia/Jakarta'},
    {country:'Unknown', value:'Asia/Jayapura',label:'Asia/Jayapura'},
    {country:'Unknown', value:'Asia/Jerusalem',label:'Asia/Jerusalem'},
    {country:'Unknown', value:'Asia/Kabul',label:'Asia/Kabul'},
    {country:'Unknown', value:'Asia/Kamchatka',label:'Asia/Kamchatka'},
    {country:'Unknown', value:'Asia/Karachi',label:'Asia/Karachi'},
    {country:'Unknown', value:'Asia/Kashgar',label:'Asia/Kashgar'},
    {country:'Unknown', value:'Asia/Katmandu',label:'Asia/Katmandu'},
    {country:'Unknown', value:'Asia/Kolkata',label:'Asia/Kolkata'},
    {country:'Unknown', value:'Asia/Krasnoyarsk',label:'Asia/Krasnoyarsk'},
    {country:'Unknown', value:'Asia/Kuala_Lumpur',label:'Asia/Kuala_Lumpur'},
    {country:'Unknown', value:'Asia/Kuching',label:'Asia/Kuching'},
    {country:'Unknown', value:'Asia/Kuwait',label:'Asia/Kuwait'},
    {country:'Unknown', value:'Asia/Macao',label:'Asia/Macao'},
    {country:'Unknown', value:'Asia/Macau',label:'Asia/Macau'},
    {country:'Unknown', value:'Asia/Magadan',label:'Asia/Magadan'},
    {country:'Unknown', value:'Asia/Makassar',label:'Asia/Makassar'},
    {country:'Unknown', value:'Asia/Manila',label:'Asia/Manila'},
    {country:'Unknown', value:'Asia/Muscat',label:'Asia/Muscat'},
    {country:'Unknown', value:'Asia/Nicosia',label:'Asia/Nicosia'},
    {country:'Unknown', value:'Asia/Novosibirsk',label:'Asia/Novosibirsk'},
    {country:'Unknown', value:'Asia/Omsk',label:'Asia/Omsk'},
    {country:'Unknown', value:'Asia/Oral',label:'Asia/Oral'},
    {country:'Unknown', value:'Asia/Phnom_Penh',label:'Asia/Phnom_Penh'},
    {country:'Unknown', value:'Asia/Pontianak',label:'Asia/Pontianak'},
    {country:'Unknown', value:'Asia/Pyongyang',label:'Asia/Pyongyang'},
    {country:'Unknown', value:'Asia/Qatar',label:'Asia/Qatar'},
    {country:'Unknown', value:'Asia/Qyzylorda',label:'Asia/Qyzylorda'},
    {country:'Unknown', value:'Asia/Rangoon',label:'Asia/Rangoon'},
    {country:'Unknown', value:'Asia/Riyadh',label:'Asia/Riyadh'},
    {country:'Unknown', value:'Asia/Riyadh87',label:'Asia/Riyadh87'},
    {country:'Unknown', value:'Asia/Riyadh88',label:'Asia/Riyadh88'},
    {country:'Unknown', value:'Asia/Riyadh89',label:'Asia/Riyadh89'},
    {country:'Unknown', value:'Asia/Saigon',label:'Asia/Saigon'},
    {country:'Unknown', value:'Asia/Sakhalin',label:'Asia/Sakhalin'},
    {country:'Unknown', value:'Asia/Samarkand',label:'Asia/Samarkand'},
    {country:'Unknown', value:'Asia/Seoul',label:'Asia/Seoul'},
    {country:'Unknown', value:'Asia/Shanghai',label:'Asia/Shanghai'},
    {country:'Unknown', value:'Asia/Singapore',label:'Asia/Singapore'},
    {country:'Unknown', value:'Asia/Taipei',label:'Asia/Taipei'},
    {country:'Unknown', value:'Asia/Tashkent',label:'Asia/Tashkent'},
    {country:'Unknown', value:'Asia/Tbilisi',label:'Asia/Tbilisi'},
    {country:'Unknown', value:'Asia/Tehran',label:'Asia/Tehran'},
    {country:'Unknown', value:'Asia/Tel_Aviv',label:'Asia/Tel_Aviv'},
    {country:'Unknown', value:'Asia/Thimbu',label:'Asia/Thimbu'},
    {country:'Unknown', value:'Asia/Thimphu',label:'Asia/Thimphu'},
    {country:'Unknown', value:'Asia/Tokyo',label:'Asia/Tokyo'},
    {country:'Unknown', value:'Asia/Ujung_Pandang',label:'Asia/Ujung_Pandang'},
    {country:'Unknown', value:'Asia/Ulaanbaatar',label:'Asia/Ulaanbaatar'},
    {country:'Unknown', value:'Asia/Ulan_Bator',label:'Asia/Ulan_Bator'},
    {country:'Unknown', value:'Asia/Urumqi',label:'Asia/Urumqi'},
    {country:'Unknown', value:'Asia/Vientiane',label:'Asia/Vientiane'},
    {country:'Unknown', value:'Asia/Vladivostok',label:'Asia/Vladivostok'},
    {country:'Unknown', value:'Asia/Yakutsk',label:'Asia/Yakutsk'},
    {country:'Unknown', value:'Asia/Yekaterinburg',label:'Asia/Yekaterinburg'},
    {country:'Unknown', value:'Asia/Yerevan',label:'Asia/Yerevan'},
    {country:'Unknown', value:'Atlantic/Azores',label:'Atlantic/Azores'},
    {country:'Unknown', value:'Atlantic/Bermuda',label:'Atlantic/Bermuda'},
    {country:'Unknown', value:'Atlantic/Canary',label:'Atlantic/Canary'},
    {country:'Unknown', value:'Atlantic/Cape_Verde',label:'Atlantic/Cape_Verde'},
    {country:'Unknown', value:'Atlantic/Faeroe',label:'Atlantic/Faeroe'},
    {country:'Unknown', value:'Atlantic/Faroe',label:'Atlantic/Faroe'},
    {country:'Unknown', value:'Atlantic/Jan_Mayen',label:'Atlantic/Jan_Mayen'},
    {country:'Unknown', value:'Atlantic/Madeira',label:'Atlantic/Madeira'},
    {country:'Unknown', value:'Atlantic/Reykjavik',label:'Atlantic/Reykjavik'},
    {country:'Unknown', value:'Atlantic/South_Georgia',label:'Atlantic/South_Georgia'},
    {country:'Unknown', value:'Atlantic/St_Helena',label:'Atlantic/St_Helena'},
    {country:'Unknown', value:'Atlantic/Stanley',label:'Atlantic/Stanley'},
    {country:'Unknown', value:'Australia/LHI',label:'Australia/LHI'},
    {country:'Unknown', value:'Australia/Lord_Howe',label:'Australia/Lord_Howe'},
    {country:'Unknown', value:'Brazil/Acre',label:'Brazil/Acre'},
    {country:'Unknown', value:'Brazil/DeNoronha',label:'Brazil/DeNoronha'},
    {country:'Unknown', value:'Brazil/East',label:'Brazil/East'},
    {country:'Unknown', value:'Brazil/West',label:'Brazil/West'},
    {country:'Unknown', value:'Chile/Continental',label:'Chile/Continental'},
    {country:'Unknown', value:'Chile/EasterIsland',label:'Chile/EasterIsland'},
    {country:'Unknown', value:'Cuba',label:'Cuba'},
    {country:'Unknown', value:'Egypt',label:'Egypt'},
    {country:'Unknown', value:'Eire',label:'Eire'},
    {country:'Unknown', value:'Europe/Amsterdam',label:'Europe/Amsterdam'},
    {country:'Unknown', value:'Europe/Andorra',label:'Europe/Andorra'},
    {country:'Unknown', value:'Europe/Athens',label:'Europe/Athens'},
    {country:'Unknown', value:'Europe/Belfast',label:'Europe/Belfast'},
    {country:'Unknown', value:'Europe/Belgrade',label:'Europe/Belgrade'},
    {country:'Unknown', value:'Europe/Berlin',label:'Europe/Berlin'},
    {country:'Unknown', value:'Europe/Bratislava',label:'Europe/Bratislava'},
    {country:'Unknown', value:'Europe/Brussels',label:'Europe/Brussels'},
    {country:'Unknown', value:'Europe/Bucharest',label:'Europe/Bucharest'},
    {country:'Unknown', value:'Europe/Budapest',label:'Europe/Budapest'},
    {country:'Unknown', value:'Europe/Chisinau',label:'Europe/Chisinau'},
    {country:'Unknown', value:'Europe/Copenhagen',label:'Europe/Copenhagen'},
    {country:'Unknown', value:'Europe/Dublin',label:'Europe/Dublin'},
    {country:'Unknown', value:'Europe/Gibraltar',label:'Europe/Gibraltar'},
    {country:'Unknown', value:'Europe/Guernsey',label:'Europe/Guernsey'},
    {country:'Unknown', value:'Europe/Helsinki',label:'Europe/Helsinki'},
    {country:'Unknown', value:'Europe/Isle_of_Man',label:'Europe/Isle_of_Man'},
    {country:'Unknown', value:'Europe/Istanbul',label:'Europe/Istanbul'},
    {country:'Unknown', value:'Europe/Jersey',label:'Europe/Jersey'},
    {country:'Unknown', value:'Europe/Kaliningrad',label:'Europe/Kaliningrad'},
    {country:'Unknown', value:'Europe/Kiev',label:'Europe/Kiev'},
    {country:'Unknown', value:'Europe/Lisbon',label:'Europe/Lisbon'},
    {country:'Unknown', value:'Europe/Ljubljana',label:'Europe/Ljubljana'},
    {country:'Unknown', value:'Europe/London',label:'Europe/London'},
    {country:'Unknown', value:'Europe/Luxembourg',label:'Europe/Luxembourg'},
    {country:'Unknown', value:'Europe/Madrid',label:'Europe/Madrid'},
    {country:'Unknown', value:'Europe/Malta',label:'Europe/Malta'},
    {country:'Unknown', value:'Europe/Mariehamn',label:'Europe/Mariehamn'},
    {country:'Unknown', value:'Europe/Minsk',label:'Europe/Minsk'},
    {country:'Unknown', value:'Europe/Monaco',label:'Europe/Monaco'},
    {country:'Unknown', value:'Europe/Moscow',label:'Europe/Moscow'},
    {country:'Unknown', value:'Europe/Nicosia',label:'Europe/Nicosia'},
    {country:'Unknown', value:'Europe/Oslo',label:'Europe/Oslo'},
    {country:'Unknown', value:'Europe/Paris',label:'Europe/Paris'},
    {country:'Unknown', value:'Europe/Podgorica',label:'Europe/Podgorica'},
    {country:'Unknown', value:'Europe/Prague',label:'Europe/Prague'},
    {country:'Unknown', value:'Europe/Riga',label:'Europe/Riga'},
    {country:'Unknown', value:'Europe/Rome',label:'Europe/Rome'},
    {country:'Unknown', value:'Europe/Samara',label:'Europe/Samara'},
    {country:'Unknown', value:'Europe/San_Marino',label:'Europe/San_Marino'},
    {country:'Unknown', value:'Europe/Sarajevo',label:'Europe/Sarajevo'},
    {country:'Unknown', value:'Europe/Simferopol',label:'Europe/Simferopol'},
    {country:'Unknown', value:'Europe/Skopje',label:'Europe/Skopje'},
    {country:'Unknown', value:'Europe/Sofia',label:'Europe/Sofia'},
    {country:'Unknown', value:'Europe/Stockholm',label:'Europe/Stockholm'},
    {country:'Unknown', value:'Europe/Tallinn',label:'Europe/Tallinn'},
    {country:'Unknown', value:'Europe/Tirane',label:'Europe/Tirane'},
    {country:'Unknown', value:'Europe/Tiraspol',label:'Europe/Tiraspol'},
    {country:'Unknown', value:'Europe/Uzhgorod',label:'Europe/Uzhgorod'},
    {country:'Unknown', value:'Europe/Vaduz',label:'Europe/Vaduz'},
    {country:'Unknown', value:'Europe/Vatican',label:'Europe/Vatican'},
    {country:'Unknown', value:'Europe/Vienna',label:'Europe/Vienna'},
    {country:'Unknown', value:'Europe/Vilnius',label:'Europe/Vilnius'},
    {country:'Unknown', value:'Europe/Volgograd',label:'Europe/Volgograd'},
    {country:'Unknown', value:'Europe/Warsaw',label:'Europe/Warsaw'},
    {country:'Unknown', value:'Europe/Zagreb',label:'Europe/Zagreb'},
    {country:'Unknown', value:'Europe/Zaporozhye',label:'Europe/Zaporozhye'},
    {country:'Unknown', value:'Europe/Zurich',label:'Europe/Zurich'},
    {country:'Unknown', value:'Hongkong',label:'Hongkong'},
    {country:'Unknown', value:'Iceland',label:'Iceland'},
    {country:'Unknown', value:'Indian/Antananarivo',label:'Indian/Antananarivo'},
    {country:'Unknown', value:'Indian/Chagos',label:'Indian/Chagos'},
    {country:'Unknown', value:'Indian/Christmas',label:'Indian/Christmas'},
    {country:'Unknown', value:'Indian/Cocos',label:'Indian/Cocos'},
    {country:'Unknown', value:'Indian/Comoro',label:'Indian/Comoro'},
    {country:'Unknown', value:'Indian/Kerguelen',label:'Indian/Kerguelen'},
    {country:'Unknown', value:'Indian/Mahe',label:'Indian/Mahe'},
    {country:'Unknown', value:'Indian/Maldives',label:'Indian/Maldives'},
    {country:'Unknown', value:'Indian/Mauritius',label:'Indian/Mauritius'},
    {country:'Unknown', value:'Indian/Mayotte',label:'Indian/Mayotte'},
    {country:'Unknown', value:'Indian/Reunion',label:'Indian/Reunion'},
    {country:'Unknown', value:'Iran',label:'Iran'},
    {country:'Unknown', value:'Israel',label:'Israel'},
    {country:'Unknown', value:'Jamaica',label:'Jamaica'},
    {country:'Unknown', value:'Japan',label:'Japan'},
    {country:'Unknown', value:'Kwajalein',label:'Kwajalein'},
    {country:'Unknown', value:'Libya',label:'Libya'},
    {country:'Unknown', value:'Mexico/BajaNorte',label:'Mexico/BajaNorte'},
    {country:'Unknown', value:'Mexico/BajaSur',label:'Mexico/BajaSur'},
    {country:'Unknown', value:'Mexico/General',label:'Mexico/General'},
    {country:'Unknown', value:'Mideast/Riyadh87',label:'Mideast/Riyadh87'},
    {country:'Unknown', value:'Mideast/Riyadh88',label:'Mideast/Riyadh88'},
    {country:'Unknown', value:'Mideast/Riyadh89',label:'Mideast/Riyadh89'},
    {country:'Unknown', value:'Navajo',label:'Navajo'},
    {country:'Unknown', value:'Pacific/Apia',label:'Pacific/Apia'},
    {country:'Unknown', value:'Pacific/Chatham',label:'Pacific/Chatham'},
    {country:'Unknown', value:'Pacific/Easter',label:'Pacific/Easter'},
    {country:'Unknown', value:'Pacific/Efate',label:'Pacific/Efate'},
    {country:'Unknown', value:'Pacific/Enderbury',label:'Pacific/Enderbury'},
    {country:'Unknown', value:'Pacific/Fakaofo',label:'Pacific/Fakaofo'},
    {country:'Unknown', value:'Pacific/Fiji',label:'Pacific/Fiji'},
    {country:'Unknown', value:'Pacific/Funafuti',label:'Pacific/Funafuti'},
    {country:'Unknown', value:'Pacific/Galapagos',label:'Pacific/Galapagos'},
    {country:'Unknown', value:'Pacific/Gambier',label:'Pacific/Gambier'},
    {country:'Unknown', value:'Pacific/Guadalcanal',label:'Pacific/Guadalcanal'},
    {country:'Unknown', value:'Pacific/Guam',label:'Pacific/Guam'},
    {country:'Unknown', value:'Pacific/Honolulu',label:'Pacific/Honolulu'},
    {country:'Unknown', value:'Pacific/Johnston',label:'Pacific/Johnston'},
    {country:'Unknown', value:'Pacific/Kiritimati',label:'Pacific/Kiritimati'},
    {country:'Unknown', value:'Pacific/Kosrae',label:'Pacific/Kosrae'},
    {country:'Unknown', value:'Pacific/Kwajalein',label:'Pacific/Kwajalein'},
    {country:'Unknown', value:'Pacific/Majuro',label:'Pacific/Majuro'},
    {country:'Unknown', value:'Pacific/Marquesas',label:'Pacific/Marquesas'},
    {country:'Unknown', value:'Pacific/Midway',label:'Pacific/Midway'},
    {country:'Unknown', value:'Pacific/Nauru',label:'Pacific/Nauru'},
    {country:'Unknown', value:'Pacific/Niue',label:'Pacific/Niue'},
    {country:'Unknown', value:'Pacific/Norfolk',label:'Pacific/Norfolk'},
    {country:'Unknown', value:'Pacific/Noumea',label:'Pacific/Noumea'},
    {country:'Unknown', value:'Pacific/Pago_Pago',label:'Pacific/Pago_Pago'},
    {country:'Unknown', value:'Pacific/Palau',label:'Pacific/Palau'},
    {country:'Unknown', value:'Pacific/Pitcairn',label:'Pacific/Pitcairn'},
    {country:'Unknown', value:'Pacific/Ponape',label:'Pacific/Ponape'},
    {country:'Unknown', value:'Pacific/Port_Moresby',label:'Pacific/Port_Moresby'},
    {country:'Unknown', value:'Pacific/Rarotonga',label:'Pacific/Rarotonga'},
    {country:'Unknown', value:'Pacific/Saipan',label:'Pacific/Saipan'},
    {country:'Unknown', value:'Pacific/Samoa',label:'Pacific/Samoa'},
    {country:'Unknown', value:'Pacific/Tahiti',label:'Pacific/Tahiti'},
    {country:'Unknown', value:'Pacific/Tarawa',label:'Pacific/Tarawa'},
    {country:'Unknown', value:'Pacific/Tongatapu',label:'Pacific/Tongatapu'},
    {country:'Unknown', value:'Pacific/Truk',label:'Pacific/Truk'},
    {country:'Unknown', value:'Pacific/Wake',label:'Pacific/Wake'},
    {country:'Unknown', value:'Pacific/Wallis',label:'Pacific/Wallis'},
    {country:'Unknown', value:'Pacific/Yap',label:'Pacific/Yap'},
    {country:'Unknown', value:'Poland',label:'Poland'},
    {country:'Unknown', value:'Portugal',label:'Portugal'},
    {country:'Unknown', value:'Singapore',label:'Singapore'},
    {country:'Unknown', value:'Turkey',label:'Turkey'},
    {country:'Unknown', value:'US/Alaska',label:'US/Alaska'},
    {country:'Unknown', value:'US/Aleutian',label:'US/Aleutian'},
    {country:'Unknown', value:'US/Arizona',label:'US/Arizona'},
    {country:'Unknown', value:'US/Central',label:'US/Central'},
    {country:'Unknown', value:'US/East-Indiana',label:'US/East-Indiana'},
    {country:'Unknown', value:'US/Eastern',label:'US/Eastern'},
    {country:'Unknown', value:'US/Hawaii',label:'US/Hawaii'},
    {country:'Unknown', value:'US/Indiana-Starke',label:'US/Indiana-Starke'},
    {country:'Unknown', value:'US/Michigan',label:'US/Michigan'},
    {country:'Unknown', value:'US/Mountain',label:'US/Mountain'},
    {country:'Unknown', value:'US/Pacific',label:'US/Pacific'},
    {country:'Unknown', value:'US/Pacific-New',label:'US/Pacific-New'},
    {country:'Unknown', value:'US/Samoa',label:'US/Samoa'},
    {country:'Unknown', value:'Zulu',label:'Zulu'}
];


export const INDUSTRY_LIST = [
    'Call Centre',
    'Childcare',
    'Food / Hospitality',
    'Health / Aged Care', 
    'Other',
    'Retail',
    'Supermarkets',
];

// popular country names
export enum POPULAR_COUNTRY_NAME {
    NZ = 'New Zealand',
    AU = 'Australia',
    US = 'United States of America',
    CA = 'Canada',
    UN = 'Unknown'   // all other coutries
}

// popular country codes

export const POPULAR_COUNTRY_CODES =['NZ','AU','US','CA'];

export const DEFAULT_COUNTRY = 'US';

export const DEFAULT_COUNTRY_FULL_NAME = 'United States of America';

export const COUNTRY_LIST = [
    {'code': 'NZ', 'value':'New Zealand'},
    {'code': 'AU', 'value':'Australia'},
    {'code': 'CA', 'value':'Canada'},
    {'code': 'US', 'value':'United States of America'},
    {'code': 'AW', 'value':'Aruba'},
    {'code': 'AF', 'value':'Afghanistan'},
    {'code': 'AO', 'value':'Angola'},
    {'code': 'AI', 'value':'Anguilla'},
    {'code': 'AX', 'value':'Åland Islands'},
    {'code': 'AL', 'value':'Albania'},
    {'code': 'AD', 'value':'Andorra'},
    {'code': 'AE', 'value':'United Arab Emirates'},
    {'code': 'AR', 'value':'Argentina'},
    {'code': 'AM', 'value':'Armenia'},
    {'code': 'AS', 'value':'American Samoa'},
    {'code': 'AQ', 'value':'Antarctica'},
    {'code': 'TF', 'value':'French Southern Territories'},
    {'code': 'AG', 'value':'Antigua and Barbuda'},
    {'code': 'AT', 'value':'Austria'},
    {'code': 'AZ', 'value':'Azerbaijan'},
    {'code': 'BI', 'value':'Burundi'},
    {'code': 'BE', 'value':'Belgium'},
    {'code': 'BJ', 'value':'Benin'},
    {'code': 'BQ', 'value':'Bonaire, Sint Eustatius and Saba'},
    {'code': 'BF', 'value':'Burkina Faso'},
    {'code': 'BD', 'value':'Bangladesh'},
    {'code': 'BG', 'value':'Bulgaria'},
    {'code': 'BH', 'value':'Bahrain'},
    {'code': 'BS', 'value':'Bahamas'},
    {'code': 'BA', 'value':'Bosnia and Herzegovina'},
    {'code': 'BL', 'value':'Saint Barthélemy'},
    {'code': 'BY', 'value':'Belarus'},
    {'code': 'BZ', 'value':'Belize'},
    {'code': 'BM', 'value':'Bermuda'},
    {'code': 'BO', 'value':'Bolivia, Plurinational State of'},
    {'code': 'BR', 'value':'Brazil'},
    {'code': 'BB', 'value':'Barbados'},
    {'code': 'BN', 'value':'Brunei Darussalam'},
    {'code': 'BT', 'value':'Bhutan'},
    {'code': 'BV', 'value':'Bouvet Island'},
    {'code': 'BW', 'value':'Botswana'},
    {'code': 'CF', 'value':'Central African Republic'},
    {'code': 'CC', 'value':'Cocos (Keeling) Islands'},
    {'code': 'CH', 'value':'Switzerland'},
    {'code': 'CL', 'value':'Chile'},
    {'code': 'CN', 'value':'China'},
    {'code': 'CI', 'value':'Côte d\'Ivoire'},
    {'code': 'CM', 'value':'Cameroon'},
    {'code': 'CD', 'value':'Congo, the Democratic Republic of the'},
    {'code': 'CG', 'value':'Congo'},
    {'code': 'CK', 'value':'Cook Islands'},
    {'code': 'CO', 'value':'Colombia'},
    {'code': 'KM', 'value':'Comoros'},
    {'code': 'CV', 'value':'Cape Verde'},
    {'code': 'CR', 'value':'Costa Rica'},
    {'code': 'CU', 'value':'Cuba'},
    {'code': 'CW', 'value':'Curaçao'},
    {'code': 'QO', 'value':'Christmas Island'},
    {'code': 'KY', 'value':'Cayman Islands'},
    {'code': 'CY', 'value':'Cyprus'},
    {'code': 'CZ', 'value':'Czech Republic'},
    {'code': 'DE', 'value':'Germany'},
    {'code': 'DJ', 'value':'Djibouti'},
    {'code': 'DM', 'value':'Dominica'},
    {'code': 'DK', 'value':'Denmark'},
    {'code': 'DO', 'value':'Dominican Republic'},
    {'code': 'DZ', 'value':'Algeria'},
    {'code': 'EC', 'value':'Ecuador'},
    {'code': 'EG', 'value':'Egypt'},
    {'code': 'ER', 'value':'Eritrea'},
    {'code': 'EH', 'value':'Western Sahara'},
    {'code': 'ES', 'value':'Spain'},
    {'code': 'EW', 'value':'Estonia'},
    {'code': 'ET', 'value':'Ethiopia'},
    {'code': 'FI', 'value':'Finland'},
    {'code': 'FJ', 'value':'Fiji'},
    {'code': 'FK', 'value':'Falkland Islands (Malvinas)'},
    {'code': 'FR', 'value':'France'},
    {'code': 'FO', 'value':'Faroe Islands'},
    {'code': 'FM', 'value':'Micronesia, Federated States of'},
    {'code': 'GA', 'value':'Gabon'},
    {'code': 'GB', 'value':'United Kingdom'},
    {'code': 'GE', 'value':'Georgia'},
    {'code': 'GG', 'value':'Guernsey'},
    {'code': 'GH', 'value':'Ghana'},
    {'code': 'GI', 'value':'Gibraltar'},
    {'code': 'GN', 'value':'Guinea'},
    {'code': 'GP', 'value':'Guadeloupe'},
    {'code': 'GM', 'value':'Gambia'},
    {'code': 'GW', 'value':'Guinea-Bissau'},
    {'code': 'GQ', 'value':'Equatorial Guinea'},
    {'code': 'GR', 'value':'Greece'},
    {'code': 'GD', 'value':'Grenada'},
    {'code': 'GL', 'value':'Greenland'},
    {'code': 'GT', 'value':'Guatemala'},
    {'code': 'GF', 'value':'French Guiana'},
    {'code': 'GU', 'value':'Guam'},
    {'code': 'GY', 'value':'Guyana'},
    {'code': 'HK', 'value':'Hong Kong'},
    {'code': 'HM', 'value':'Heard Island and McDonald Islands'},
    {'code': 'HN', 'value':'Honduras'},
    {'code': 'HR', 'value':'Croatia'},
    {'code': 'HT', 'value':'Haiti'},
    {'code': 'HU', 'value':'Hungary'},
    {'code': 'ID', 'value':'Indonesia'},
    {'code': 'IM', 'value':'Isle of Man'},
    {'code': 'IN', 'value':'India'},
    {'code': 'IE', 'value':'Ireland'},
    {'code': 'IR', 'value':'Iran, Islamic Republic of'},
    {'code': 'IQ', 'value':'Iraq'},
    {'code': 'IS', 'value':'Iceland'},
    {'code': 'IL', 'value':'Israel'},
    {'code': 'IT', 'value':'Italy'},
    {'code': 'JM', 'value':'Jamaica'},
    {'code': 'JE', 'value':'Jersey'},
    {'code': 'JO', 'value':'Jordan'},
    {'code': 'JP', 'value':'Japan'},
    {'code': 'KZ', 'value':'Kazakhstan'},
    {'code': 'KE', 'value':'Kenya'},
    {'code': 'KG', 'value':'Kyrgyzstan'},
    {'code': 'KH', 'value':'Cambodia'},
    {'code': 'KI', 'value':'Kiribati'},
    {'code': 'KN', 'value':'Saint Kitts and Nevis'},
    {'code': 'KR', 'value':'Korea, Republic of'},
    {'code': 'KW', 'value':'Kuwait'},
    {'code': 'LA', 'value':'Lao People\'s Democratic Republic'},
    {'code': 'LB', 'value':'Lebanon'},
    {'code': 'LR', 'value':'Liberia'},
    {'code': 'LY', 'value':'Libya'},
    {'code': 'LC', 'value':'Saint Lucia'},
    {'code': 'FL', 'value':'Liechtenstein'},
    {'code': 'LK', 'value':'Sri Lanka'},
    {'code': 'LS', 'value':'Lesotho'},
    {'code': 'LT', 'value':'Lithuania'},
    {'code': 'LU', 'value':'Luxembourg'},
    {'code': 'LV', 'value':'Latvia'},
    {'code': 'MO', 'value':'Macao'},
    {'code': 'MF', 'value':'Saint Martin (French part)'},
    {'code': 'MA', 'value':'Morocco'},
    {'code': 'MC', 'value':'Monaco'},
    {'code': 'MD', 'value':'Moldova, Republic of'},
    {'code': 'RM', 'value':'Madagascar'},
    {'code': 'MV', 'value':'Maldives'},
    {'code': 'MX', 'value':'Mexico'},
    {'code': 'MH', 'value':'Marshall Islands'},
    {'code': 'MK', 'value':'Macedonia, the former Yugoslav Republic of'},
    {'code': 'ML', 'value':'Mali'},
    {'code': 'MT', 'value':'Malta'},
    {'code': 'MM', 'value':'Myanmar'},
    {'code': 'ME', 'value':'Montenegro'},
    {'code': 'MN', 'value':'Mongolia'},
    {'code': 'MP', 'value':'Northern Mariana Islands'},
    {'code': 'MZ', 'value':'Mozambique'},
    {'code': 'MR', 'value':'Mauritania'},
    {'code': 'MS', 'value':'Montserrat'},
    {'code': 'MQ', 'value':'Martinique'},
    {'code': 'MU', 'value':'Mauritius'},
    {'code': 'MW', 'value':'Malawi'},
    {'code': 'MY', 'value':'Malaysia'},
    {'code': 'YT', 'value':'Mayotte'},
    {'code': 'NA', 'value':'Namibia'},
    {'code': 'NC', 'value':'New Caledonia'},
    {'code': 'NE', 'value':'Niger'},
    {'code': 'NF', 'value':'Norfolk Island'},
    {'code': 'NG', 'value':'Nigeria'},
    {'code': 'NI', 'value':'Nicaragua'},
    {'code': 'NU', 'value':'Niue'},
    {'code': 'NL', 'value':'Netherlands'},
    {'code': 'NO', 'value':'Norway'},
    {'code': 'NP', 'value':'Nepal'},
    {'code': 'NR', 'value':'Nauru'},
    {'code': 'OM', 'value':'Oman'},
    {'code': 'PK', 'value':'Pakistan'},
    {'code': 'PA', 'value':'Panama'},
    {'code': 'PN', 'value':'Pitcairn'},
    {'code': 'PE', 'value':'Peru'},
    {'code': 'PH', 'value':'Philippines'},
    {'code': 'PW', 'value':'Palau'},
    {'code': 'PG', 'value':'Papua New Guinea'},
    {'code': 'PL', 'value':'Poland'},
    {'code': 'PR', 'value':'Puerto Rico'},
    {'code': 'KP', 'value':'Korea, Democratic People\'s Republic of'},
    {'code': 'PT', 'value':'Portugal'},
    {'code': 'PY', 'value':'Paraguay'},
    {'code': 'PS', 'value':'Palestine, State of'},
    {'code': 'PF', 'value':'French Polynesia'},
    {'code': 'QA', 'value':'Qatar'},
    {'code': 'RE', 'value':'Réunion'},
    {'code': 'RO', 'value':'Romania'},
    {'code': 'RU', 'value':'Russian Federation'},
    {'code': 'RW', 'value':'Rwanda'},
    {'code': 'SA', 'value':'Saudi Arabia'},
    {'code': 'SD', 'value':'Sudan'},
    {'code': 'SN', 'value':'Senegal'},
    {'code': 'SG', 'value':'Singapore'},
    {'code': 'GS', 'value':'South Georgia and the South Sandwich Islands'},
    {'code': 'SH', 'value':'Saint Helena, Ascension and Tristan da Cunha'},
    {'code': 'SJ', 'value':'Svalbard and Jan Mayen'},
    {'code': 'SB', 'value':'Solomon Islands'},
    {'code': 'SL', 'value':'Sierra Leone'},
    {'code': 'SV', 'value':'El Salvador'},
    {'code': 'SM', 'value':'San Marino'},
    {'code': 'SO', 'value':'Somalia'},
    {'code': 'PM', 'value':'Saint Pierre and Miquelon'},
    {'code': 'RS', 'value':'Serbia'},
    {'code': 'SS', 'value':'South Sudan'},
    {'code': 'ST', 'value':'Sao Tome and Principe'},
    {'code': 'SR', 'value':'Suriname'},
    {'code': 'SK', 'value':'Slovakia'},
    {'code': 'SI', 'value':'Slovenia'},
    {'code': 'SE', 'value':'Sweden'},
    {'code': 'SZ', 'value':'Swaziland'},
    {'code': 'SX', 'value':'Sint Maarten (Dutch part)'},
    {'code': 'SC', 'value':'Seychelles'},
    {'code': 'SY', 'value':'Syrian Arab Republic'},
    {'code': 'TC', 'value':'Turks and Caicos Islands'},
    {'code': 'TD', 'value':'Chad'},
    {'code': 'TG', 'value':'Togo'},
    {'code': 'TH', 'value':'Thailand'},
    {'code': 'TJ', 'value':'Tajikistan'},
    {'code': 'TK', 'value':'Tokelau'},
    {'code': 'TM', 'value':'Turkmenistan'},
    {'code': 'TL', 'value':'Timor-Leste'},
    {'code': 'TO', 'value':'Tonga'},
    {'code': 'TT', 'value':'Trinidad and Tobago'},
    {'code': 'TN', 'value':'Tunisia'},
    {'code': 'TR', 'value':'Turkey'},
    {'code': 'TV', 'value':'Tuvalu'},
    {'code': 'TW', 'value':'Taiwan, Province of China'},
    {'code': 'TZ', 'value':'Tanzania, United Republic of'},
    {'code': 'UG', 'value':'Uganda'},
    {'code': 'UA', 'value':'Ukraine'},
    {'code': 'UM', 'value':'United States Minor Outlying Islands'},
    {'code': 'UY', 'value':'Uruguay'},
    {'code': 'UZ', 'value':'Uzbekistan'},
    {'code': 'VA', 'value':'Holy See (Vatican City State)'},
    {'code': 'VC', 'value':'Saint Vincent and the Grenadines'},
    {'code': 'VE', 'value':'Venezuela, Bolivarian Republic of'},
    {'code': 'VG', 'value':'Virgin Islands, British'},
    {'code': 'VI', 'value':'Virgin Islands, U.S.'},
    {'code': 'VN', 'value':'Viet Nam'},
    {'code': 'VU', 'value':'Vanuatu'},
    {'code': 'WF', 'value':'Wallis and Futuna'},
    {'code': 'WS', 'value':'Samoa'},
    {'code': 'YE', 'value':'Yemen'},
    {'code': 'ZA', 'value':'South Africa'},
    {'code': 'ZM', 'value':'Zambia'},
    {'code': 'ZW', 'value':'Zimbabwe'},
    {'code': 'IO', 'value':'British Indian Ocean Territory (the)'},
];

export const DEFAULT_INDUSTRY = 'Childcare';
