import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link, Container, CssBaseline, useMediaQuery, CardContent, Card, CardHeader, Backdrop, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Footer from '../components/Footer';
import PageMsg, { IPageMsg } from '../components/PageMsg';
import RiseLoader from 'react-spinners/RiseLoader';
import { DEFAULT_INDUSTRY } from '../utils/Constant';
import ReactGA from 'react-ga';
import { StepKey, SubscriptionStepper } from '../components/SubscriptionStepper';
import { CheckCircle } from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { IClientSecret, ISignUpForm } from '../models/subscription';
import { styles } from '../style/subscription';
import * as api from '../api/subscription';
import { findCountryFullName, findTimezoneByCountry } from '../utils/Index';
import UserDetails from '../components/UserDetails';
import OrganizationDetails from '../components/OrganizationDetails';
import Confirmation from '../components/Confirmation';
import Payment from '../components/Payment';
import OptionalDetails from '../components/OptionalDetails';

enum ESubmitStatus {
   'Not_Submitting',
   'Submitting'
}

function initializeForm(searchParams: URLSearchParams) : ISignUpForm{
   const countryName = findCountryFullName(searchParams.get('country_code') || searchParams.get('country'))//country_code when returning from Stripe
   const selectedTimezone = findTimezoneByCountry(countryName)
   return {
      firstName: '',
      lastName: '',
      email: '',
      telephone: '',
      password: '',
      role: '',
      organization: '',
      numberOfSite: 1,
      state: '',
      timezone: selectedTimezone,
      country: countryName,
      industry:  searchParams.get('industry') || DEFAULT_INDUSTRY,
      promoCode: (searchParams.get('promo_code') || '').trim().toUpperCase(),
      partner: false,
      plan: '',
      users: [
         { firstName: '', lastName: '', email: '' },
         { firstName: '', lastName: '', email: '' }
      ],
      checkedTerm: false, //not submitted, for form control only
      checkedPolicy: false, //not submitted, for form control only
      obw: false, //not submitted, for form control only
      price: null,
      priceValue: null,
      proceedWithoutImplementationPackage: false,
      implementationPackagePrice: null,
      implementationPackageRequired: false,
      implementationPackages: [],
      implementationItems: [],
      implementationItemsPrice: null,
      minFranchiseeNumberForImpPack: null
   }
}

export default withStyles(styles)((props: any) => {

   //URL params
   const searchParams = new URLSearchParams(props.location.search)
   const paymentIntentId = searchParams.get('payment_intent') //when redirected from Stripe

   //check device width, is it mobile or pad?
   const largeScreenMode = useMediaQuery('(min-width:1025px)')
   const { executeRecaptcha } = useGoogleReCaptcha()
   const divRef = useRef<HTMLDivElement>(null);
   const [pageMsgWrapper, setPageMsgWrapper] = useState<IPageMsg | null>()
   const [activeStep, setActiveStep] = useState<StepKey>('user_details')
   const [optionalSteps, setOptionalSteps] = useState<{optional: boolean, payment: boolean}>({optional: false, payment: false})
   const [submitStatus, setSubmitStatus] = useState<ESubmitStatus>(ESubmitStatus.Not_Submitting)

   //Payment state
   const [showForm, setShowForm] = useState<boolean>(!paymentIntentId) //don't show form now if user is returning from payment
   const [clientSecret, setClientSecret] = useState<IClientSecret | null>(null) //we retrieve this if user is returning from payment

   const [error, setError] = useState<string>()
   const [subscriptionSuccessful, setSubscriptionSuccessful] = useState<boolean>(false)
   const [signUpFormData, setSignUpFormData] = React.useState<ISignUpForm>(initializeForm(searchParams))

   const clearMessage = () => setPageMsgWrapper(null)

   const showMessage = (msg: IPageMsg) => {
      clearMessage()
      setPageMsgWrapper(msg)
      scrollTop()
   }

   const executeRecaptchaForAction = React.useCallback(
      async (action: string): Promise<string | null> => {
         if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available')
            return null
         }
         return executeRecaptcha(action) //return token
      },
      [executeRecaptcha]
   )

   const next = async (formData: ISignUpForm) => {
      setSignUpFormData({ ...signUpFormData, ...formData })
      setSubmitStatus(ESubmitStatus.Submitting);

      try {
         const token = await executeRecaptchaForAction('next');
         if (!token) {
            setSubmitStatus(ESubmitStatus.Not_Submitting);
            return
         }

         if (activeStep === 'user_details') {//first step
            const verifyResponse = await api.verify(token)
            if (verifyResponse.status >= 300) {
               setPageMsgWrapper({
                  severity: 'warning',
                  pageMsg: 'Error submitting your request. Please try. It problems exists please try refreshing the page',
                  includeLink: false
               })
               setSubmitStatus(ESubmitStatus.Not_Submitting)
               return
            }
            setActiveStep('org_details')
         } 
         //Second step, validate data and get subscription/billing config
         else if(activeStep === 'org_details'){
            const response = await api.validateInstance({...formData, users: []}, token) //we don't want to validate additional users' emails here (in case user returns to this step)
            if (response.error) {
               setPageMsgWrapper({
                  severity: 'error',
                  pageMsg: response.message!,
                  includeLink: false
               })
               setSubmitStatus(ESubmitStatus.Not_Submitting)
               scrollTop() //page is long
               return
            } else {
               setOptionalSteps({
                  optional: !response.obw, //show optional step if preset has no obw
                  payment: !!response.price, //show payment step if preset has price
               })            
               setSignUpFormData({ ...formData, 
                  obw: response.obw || false, 
                  price: response.price || null, 
                  priceValue: response.priceValue || null,
                  plan: response.plan || '', 
                  partner : !!response.partner,
                  promoCode: signUpFormData.promoCode || response.promoCode || '',
                  implementationPackageRequired: response.implementationPackageRequired || false,
                  implementationPackages: response.implementationPackages || [], 
                  implementationItems: response.implementationItems || [],
                  implementationItemsPrice: null,
                  implementationPackagePrice: null,
                  proceedWithoutImplementationPackage: false,
                  minFranchiseeNumberForImpPack: response.minFranchiseeNumberForImpPack || null
               })
               setActiveStep(response.obw ? 'confirm' : 'optional_details')
            }            
         }
         //third step, optional, validate data + user emails, subscription/billing config already retrieved in last step
         else if(activeStep === 'optional_details'){
            const response = await api.validateInstance(formData, token)
            if (response.error) {
               setPageMsgWrapper({
                  severity: 'error',
                  pageMsg: response.message!,
                  includeLink: false
               })
               setSubmitStatus(ESubmitStatus.Not_Submitting)
               return
            }            
            setActiveStep('confirm')
         }
         else if(activeStep === 'confirm'){//fourth step, submit data
            if(optionalSteps.payment) setActiveStep('payment')
         }

         clearMessage()
      } catch (e) { }
      setSubmitStatus(ESubmitStatus.Not_Submitting)
   }

   const signup = async (formData: ISignUpForm) => {
      setSignUpFormData({ ...signUpFormData, ...formData })
      setSubmitStatus(ESubmitStatus.Submitting);
      try {
         //send form data to google analytics
         ReactGA.event({
            category: 'Submit',
            action: JSON.stringify(formData)
         });
         const result = await executeRecaptchaForAction('signup')

         //create 1Place instance
         api.createInstance(formData, result!)

         //update zoho
         api.updateZoho(formData)
         
         clearMessage()
         if(optionalSteps.payment){
            setActiveStep('payment')
         } else {
            setSubscriptionSuccessful(true)
         }
      } catch(e){ console.log(e)}
      setSubmitStatus(ESubmitStatus.Not_Submitting)
   }

   useEffect(() => {
      //See: https://stripe.com/docs/payments/intents#intent-statuses
      async function checkPaymentIntentStatus() {
         const piWrapper = await api.getPaymentIntent(paymentIntentId!, signUpFormData.country)

         //payment intent not found
         if (!piWrapper || !piWrapper.paymentIntent) {
            //show error message
            setError('There was a problem retrieving your Payment or it has been canceled.')
            return
         }

         const paymentIntent = piWrapper?.paymentIntent
         switch (paymentIntent.status) {
            case 'succeeded':
            case 'processing':
               //user has already paid this intent or still processing (in which case we have to wait)
               setSubscriptionSuccessful(true)
               break
            case 'requires_payment_method':
               //payment might have been declined, show payment form again
               setClientSecret({
                  clientSecret: paymentIntent.client_secret!, 
                  pk: piWrapper.pk
               })
               setActiveStep('payment')
               setShowForm(true)
               setPageMsgWrapper({
                  severity: 'error',
                  pageMsg: `There was an error processing your payment. 
            Please try again with a different Card or contact our team (support@1placeonline.com).`,
                  includeLink: false
               });
               break
            default:
               //we ignore any other status and start the process again
               setError('There was a problem retrieving your Payment or it has been canceled.')
         }
      }
      if (paymentIntentId)
         checkPaymentIntentStatus()
   },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [paymentIntentId]
   )

   const scrollTop = () => {
      let node = ReactDOM.findDOMNode(divRef.current) as Element;
      node.scrollIntoView({ block: 'start', behavior: 'smooth' });
   }

   const { classes } = props
   return (
      <React.Fragment>
         <CssBaseline />
         <Grid container component='main' className={classes.root}>
            <Grid item xs={12}>
               <Container
                  maxWidth='sm'
                  className={largeScreenMode ?
                     classes.large_screen_content : classes.mobile_screen_content
                  }
               >
                  <Box>
                     <img src="1place_logo.png" alt='' width='160'></img>
                  </Box>
                  <Card style={{ minHeight: '63vh', border: 'none', boxShadow: 'none' }}>
                     {error && <ErrorMessage error={error} />}
                     {subscriptionSuccessful && <SubscriptionSuccessfulMessage />}
                     {
                        showForm
                        && !error
                        && !subscriptionSuccessful &&
                        <>
                           <SignupHereMessage />
                           {!clientSecret && <SubscriptionStepper step={activeStep} show={optionalSteps} />}
                           <CardContent>
                              <Container innerRef={divRef}>
                                 {(submitStatus === ESubmitStatus.Not_Submitting && pageMsgWrapper) &&
                                    <PageMsg severity={pageMsgWrapper.severity} pageMsg={pageMsgWrapper.pageMsg} includeLink={pageMsgWrapper.includeLink} />
                                 }
                                 <form onSubmit={() => null}>
                                    <Grid container spacing={1} direction='row' alignItems='center'>
                                       {activeStep === 'user_details' &&
                                          <UserDetails
                                             onValidationFormError={showMessage}
                                             signupFormData={signUpFormData}
                                             onNext={next}
                                          />
                                       }
                                       {activeStep === 'org_details' &&
                                          <OrganizationDetails
                                             onValidationFormError={showMessage}
                                             signupFormData={signUpFormData}
                                             onNext={next}
                                             onBack={(formData) => {
                                                setSignUpFormData({ ...signUpFormData, ...formData })
                                                setActiveStep('user_details')
                                                clearMessage()
                                             }}
                                          />
                                       }
                                        {activeStep === 'optional_details' &&
                                          <OptionalDetails
                                             onValidationFormError={showMessage}
                                             signupFormData={signUpFormData}
                                             onNext={next}
                                             onBack={(formData) => {
                                                setSignUpFormData({ ...signUpFormData, ...formData })
                                                setActiveStep('org_details')
                                                clearMessage()
                                             }}
                                          />
                                       }
                                       {activeStep === 'confirm' &&
                                          <Confirmation
                                             onValidationFormError={showMessage}
                                             signupFormData={signUpFormData}
                                             onConfirm={signup}
                                             isSubmitting={submitStatus === ESubmitStatus.Submitting}
                                             onBack={(formData) => {
                                                setSignUpFormData({ ...signUpFormData, ...formData })
                                                setActiveStep(optionalSteps.optional ? 'optional_details' : 'org_details')
                                                clearMessage()
                                             }}
                                          />
                                       }
                                       {activeStep === 'payment' &&
                                          <Payment
                                             signupFormData={signUpFormData}
                                             onPaymentSubmitted={() => setSubmitStatus(ESubmitStatus.Submitting)}
                                             clientSecret={clientSecret}
                                             onValidationFormError={(msg) => {
                                                showMessage(msg)
                                                setSubmitStatus(ESubmitStatus.Not_Submitting)
                                             }}
                                          />
                                       }
                                       <Backdrop className={classes.backdrop} open={submitStatus === ESubmitStatus.Submitting}>
                                          <RiseLoader
                                             color={'#f50057'}
                                             loading={true}
                                          />
                                       </Backdrop>
                                    </Grid>
                                 </form>
                              </Container>
                           </CardContent>
                        </>
                     }
                  </Card>
                  <Footer />
               </Container>
            </Grid>

         </Grid>
      </React.Fragment>
   )
})


const SubscriptionSuccessfulMessage = () => {
   return (
      <>
         <CardHeader
            role='success'
            title={'Thank You'}
            titleTypographyProps={{ variant: 'h4' }}
            style={{ textAlign: 'center' }}
         />
         <CardHeader
            title={'Your sign up was successful!'}
            titleTypographyProps={{ variant: 'h6' }}
            style={{ textAlign: 'center' }}
         />
         <CardContent style={{ marginLeft: 20, marginRight: 20 }}>
            <Box textAlign='center'>
               <CheckCircle style={{ color: 'green', fontSize: 100 }} />
            </Box>
            <Typography variant='h6'>
               Thank you for signing up. We're getting your account ready which should only take a few minutes. 
               We'll email you when it is ready. If you do not receive an email, please check your spam folder, otherwise contact us at
               <Link href='mailto: support@1placeonline.com'> support@1placeonline.com</Link>
            </Typography>            
         </CardContent>
         </>
   )
}

const ErrorMessage = (props: any) => {
   return (
      <>
         <CardHeader
            role='error'
            title={'Oops!'}
            titleTypographyProps={{ variant: 'h4' }}
            style={{ textAlign: 'center' }}
         />
         <CardHeader
            title={'Something went wrong'}
            titleTypographyProps={{ variant: 'h6' }}
            style={{ textAlign: 'center' }}
         />
         <CardContent style={{ marginLeft: 20, marginRight: 20 }}>
            <Box textAlign='center'>
               <ErrorIcon style={{ color: 'red', fontSize: 100 }} />
            </Box>
            <Typography variant='h6'>
               {props.error} Please contact us at <Link href='mailto: support@1placeonline.com'> support@1placeonline.com</Link>
            </Typography>
         </CardContent>
      </>
   )
}

const SignupHereMessage = () => {
   return (
      <>
         <Grid container spacing={1} direction='row' alignItems='flex-start' style={{ marginTop: '10px', marginBottom: '-20px', marginLeft: '36px' }}>
            <Grid item xs={2} >
               <hr style={{ backgroundColor: '#65C9CA', height: '3px', width: '100%', border: '0' }}></hr>
            </Grid>
         </Grid>
         <Grid container spacing={0} direction='row' alignItems='flex-start' style={{ marginBottom: '-20px', marginLeft: '40px' }}>
            <Grid>
               <h2 style={{ color: '#223d79' }}>Please Sign Up Here</h2>
            </Grid>
         </Grid>
      </>
   )
}