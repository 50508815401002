import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../style/subscription';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { createPaymentIntent } from '../api/subscription';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { IPageMsg } from './PageMsg';
import { Grid } from '@material-ui/core';
import ActionButtons from './ActionButtons';
import { IClientSecret, ISignUpForm } from '../models/subscription';
import { findCountryIsoName } from '../utils/Index';
import PaymentSummary from './PaymentSummary';


export type PaymentUserMetadata = {
   email: string
   phone: string
   partner: string
}
interface IPaymentProps extends IPaymentFormProps {
   signupFormData: ISignUpForm
   clientSecret: IClientSecret | null //if payment failed and user is returning through stripe url callback
}

/*Payment is a wrapper for PaymentForm component which 
cannot use stripe hooks until ElementsProvider is ready*/
const Payment = (props: IPaymentProps) => {

   const [secretKey, setSecretKey] = useState<string>()
   const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>| null>()
   
   useEffect(() => {
      const fetchKeys = async () =>{ 
         const csWrapper = await createPaymentIntent(props.signupFormData)
         setSecretKey(csWrapper.clientSecret)
         setStripePromise(() => loadStripe(csWrapper.pk))
      }
      if (props.clientSecret){
         setSecretKey(props.clientSecret.clientSecret)
         setStripePromise(() => loadStripe(props.clientSecret!.pk))
      }        
      else {
         fetchKeys()
      }        
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   return (
      <>
         {/* if user gets error during payment and return to this form, we won't show payment summary. 
         We have to retrieve the price, implementation price selected and number of centers from Stripe 
         subscription if we want to show that information again */}
         {props.signupFormData.price && 
            <Grid item xs={12}>
               <PaymentSummary signupFormData={props.signupFormData} />
            </Grid>
         }
         {secretKey && stripePromise &&
            <Elements stripe={stripePromise} options={{ clientSecret: secretKey }}>
               <PaymentForm
                  onValidationFormError={props.onValidationFormError}
                  onPaymentSubmitted={props.onPaymentSubmitted}
                  signupFormData={props.signupFormData}
               />
            </Elements>
         }
      </>
   )
}
export default withStyles(styles)(Payment);


interface IPaymentFormProps {
   onValidationFormError: (message: IPageMsg) => void
   onPaymentSubmitted: () => void
   signupFormData: ISignUpForm
}

const PaymentForm = (props: IPaymentFormProps) => {

   const stripe = useStripe();
   const elements = useElements();
   const [isSubmitting, setSubmitting] = useState<boolean>(false)
   const [isLoadingForm, setLoadingForm] = useState<boolean>(true)

   const confirmPayment = async () => {
      try {
         if (!stripe || !elements) return

         const countryCode = findCountryIsoName(props.signupFormData.country)
         setSubmitting(true)
         props.onPaymentSubmitted()
         const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
               return_url: `${process.env.REACT_APP_STRIPE_CALLBACK_URL}?country_code=${countryCode}`,
            },
         })

         if (error) {
            // This point will only be reached if there is an immediate error when confirming the payment. 
            props.onValidationFormError({
               severity: 'error',
               pageMsg: error.message || 'No error message',
               includeLink: false
            })
         } else {
            // All good, user will be redirected to our callback url.
            return
         }
      } catch (e) { console.log(e) }

      setSubmitting(false)
   }

   return (
      <>
         <Grid item xs={12} data-testid='payment-form'>
            <PaymentElement onReady={() => setLoadingForm(false)} options={{ readOnly: isSubmitting }} />
         </Grid>
         <ActionButtons
            disableBack={true}
            showConfirm={true}
            disableConfirm={(!stripe || !elements) || isSubmitting || isLoadingForm}
            onClickConfirm={confirmPayment}
         />
      </>
   )
}
