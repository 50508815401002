import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { AppRouter } from './AppRouter';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import TagManager from 'react-gtm-module-nonce'
const theme = createMuiTheme({

  // exclude ipad pro from displaying the landing page
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1025,  //old value was 960
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
      primary: {
          main: '#223d79',
          light: '#5567a8',
          dark: '#00184c',
          contrastText: '#fff',
      },
      secondary: {
          main: '#f50057',
          light: '#ff5983',
          dark: '#bb002f',
          contrastText: '#fff',
      }
  },
  // fix up margin for keyboardDatePicker picker
  overrides: {
      MuiFormControl: {
              root: {
              marginTop: '16px'
       }
      }
    }
});

const cache = createCache({
  key: 'jss-class-prefix-key',
  nonce: document.querySelector('meta[property=\'csp-nonce\']')?.getAttribute('content') || '',
  prepend: true,
});

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_ID,
  nonce: document.querySelector('meta[property=\'csp-script-nonce\']')?.getAttribute('content') || ''
}
TagManager.initialize(tagManagerArgs)

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY;

const App = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
      <MuiThemeProvider theme={theme}>
        <CacheProvider value={cache}>
          <AppRouter />
        </CacheProvider>
      </MuiThemeProvider>
    </GoogleReCaptchaProvider>
    );
}

export default App;
