import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {Typography, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../style/subscription';
import { ISignUpForm } from '../models/subscription';
import { IPageMsg } from './PageMsg';
import ActionButtons from './ActionButtons';
import * as EmailValidator from 'email-validator';
import Alert from '@material-ui/lab/Alert';

interface IOptionalDetailsProps extends WithStyles<typeof styles> {
   signupFormData: ISignUpForm
   onValidationFormError: (message: IPageMsg) => void
   onNext: (value: ISignUpForm) => void
   onBack: (value: ISignUpForm) => void
}

const OptionalDetails = (props: IOptionalDetailsProps) => {

   const [signUpFormData, setSignUpFormData] = React.useState<ISignUpForm>(props.signupFormData);

   const next = () => {
      let error;
      const user1Email = signUpFormData.users[0].email.trim()
      const user2Email = signUpFormData.users[1].email.trim()

      if (!!user1Email && !EmailValidator.validate(user1Email))
         error = `Email address for User 1 is invalid.`
      else if (!!user2Email && !EmailValidator.validate(user2Email))
         error = `Email address for User 2 is invalid.`
      else {
         //check if all info was filled for additional users
         signUpFormData.users.some((user, idx) => {
            const filledFields = Object.values(user).filter(value => value.trim() !== '').length
            if (filledFields > 0 && filledFields !== 3) {
               error = `Please complete all fields for User ${idx + 1}.`
               return true
            }
            return false
         })

         //check if emails are equal
         if (!error && (!!user1Email || !!user2Email)) {
            if (signUpFormData.email === user1Email
               || signUpFormData.email === user2Email)
               error = 'The email addresses must be different from the one entered on \'Your Details\'.'
            else if (user1Email === user2Email)
               error = 'The email addresses must be different.'
         }
      }

      if (error)
         props.onValidationFormError({
            severity: 'error',
            pageMsg: error,
            includeLink: false
         })
      else
         props.onNext(signUpFormData)
   }

   return (
      <>
         <Grid item xs={12}>
            <Typography>Add up to 2 Leadership Users (Optional)</Typography>
            <hr style={hrStyle} />
         </Grid>
         {signUpFormData.users.map((user, idx) =>
            <React.Fragment key={idx}>
               <Grid item xs={12} sm={6}>
                  <TextField
                     variant='outlined'
                     id={`firstName-${idx}`}
                     name={`firstName-${idx}`}
                     label={`User ${idx + 1} First Name`}
                     value={user.firstName}
                     fullWidth
                     onChange={(event) => {
                        const usersCopy = [...signUpFormData.users]
                        usersCopy[idx] = { ...user, firstName: event.target.value }
                        setSignUpFormData({ ...signUpFormData, users: usersCopy })
                     }}
                  />
               </Grid>
               <Grid item xs={12} sm={6}>
                  <TextField
                     variant='outlined'
                     id={`lastName-${idx}`}
                     name={`lastName-${idx}`}
                     label={`User ${idx + 1} Last Name`}
                     value={user.lastName}
                     fullWidth
                     onChange={(event) => {
                        const usersCopy = [...signUpFormData.users]
                        usersCopy[idx] = { ...user, lastName: event.target.value }
                        setSignUpFormData({ ...signUpFormData, users: usersCopy })
                     }}
                  />
               </Grid>
               <Grid item xs={12}>
                  <TextField
                     variant='outlined'
                     id={`email-${idx}`}
                     name={`email-${idx}`}
                     label={`User ${idx + 1} Email`}
                     value={user.email}
                     fullWidth
                     onChange={(event) => {
                        const usersCopy = [...signUpFormData.users]
                        usersCopy[idx] = { ...user, email: event.target.value }
                        setSignUpFormData({ ...signUpFormData, users: usersCopy })
                     }}
                  />
               </Grid>
            </React.Fragment>
         )}
         <Grid item xs={12}>
            <Alert severity='warning'>
               Please note your Password will be the same for all Users initially created in your Account.
            </Alert>
         </Grid>
         <ActionButtons
            disableBack={false}
            onClickBack={() => props.onBack(signUpFormData)}
            showNext={true}
            onClickNext={next}
         />
      </>
   )
}
export default withStyles(styles)(OptionalDetails);

const hrStyle = {
   border: 0,
   height: 1,
   backgroundColor: 'lightgray'
}