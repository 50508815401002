import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Checkbox, FormControlLabel, TextField, Typography, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../style/subscription';
import { ISignUpForm } from '../models/subscription';

interface ICustomItemsProps extends WithStyles<typeof styles> {
   signupFormData: ISignUpForm
   onEnterQuantity: (id: number, quantity: number) => void
   onChangeSelection: (selected: boolean) => void
}

const CustomItems = (props: ICustomItemsProps) => {   
   const customItems = props.signupFormData.implementationItems
   return (
      <>
         <Grid item xs={12} style={{marginTop: 10}}>
            <FormControlLabel
                     control={<Checkbox color='primary'
                        checked={props.signupFormData.implementationItemsPrice !== null}
                        data-testid='no-implementation-package'
                        onChange={(event: any) => {
                           if(event.target.checked){ //reset
                              props.onEnterQuantity(customItems[0].id, 0)
                           } else {
                              props.onChangeSelection(false)
                           }
                        }}
                     />}
                     label={<Typography variant='body1'>Create Custom Implementation Package</Typography>}
                  />
            <hr/>
         </Grid>
         <Grid container style={{border: '0px solid #949494', borderRadius: 5, padding: 10}}>
            <Grid item xs={12}>
         {customItems.map((item, idx) => {
            return (
               <Grid item xs={12} key={idx}>     
                  <TextField
                     variant='outlined'
                     color='primary'
                     label={`${item.description} ($${item.priceValue})`}
                     value={item.quantity}
                     InputProps={{ inputProps: { min: 0 } }}
                     fullWidth
                     type='Number'
                     onChange={(event) => {
                        let value = event.target.value || '0'                
                        let isValidNumber = value && value.trim() !== '' && !isNaN(Number(value))
                        if(isValidNumber)
                           props.onEnterQuantity(item.id, parseInt(value, 10))
                     }}
                     InputLabelProps={{ shrink: true }}
                     onFocus={(event: any) => event.target.select()}
                  />
               </Grid>    
            )
         })} </Grid>
         </Grid>
      </>
   )
}
export default withStyles(styles)(CustomItems);